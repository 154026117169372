import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticationService } from "../../services/authenticationService";
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (currentUser) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              //pathname: "/signin",
              pathname: "/",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
