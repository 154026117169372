import { errorResponseSchema, userResponseSchema } from "../validator-schemas";
import { authenticationService } from "../services/authenticationService";

export function handleAuthResponse(res) {
  if (res.status === 200) {
    const user = res.data;
    if (user) {
      const validateErrors = userResponseSchema.validate(user);
      if (validateErrors.length === 0) {
        return user;
      } else {
        return Promise.reject({
          errors: ["Invalid user info response."]
        });
      }
    }
    return Promise.reject({
      errors: ["Unknown user info response."]
    });
  }
}

export function handleGetProjectListResponse(res) {
  if (res.status === 200) {
    const projects = res.data;
    if (projects) {
      return projects;
    } else {
      return Promise.reject({
        errors: ["Invalid project list response."]
      });
    }
  }
}

export function handleGetProjectInfoResponse(res) {
  if (res.status === 200) {
    const projectInfo = res.data;
    if (projectInfo) {
      return projectInfo;
    } else {
      return Promise.reject({
        errors: ["Invalid project info response."]
      });
    }
  }
}
export function handleSavePdoMapsFileResponse(res) {
  if (res.status === 200) {
    const responseData = res.data;
    if (responseData) {
      return responseData;
    } else {
      return Promise.reject({
        errors: ["Invalid project save pdo maps file response."]
      });
    }
  }
}

export function handleFetchPdoMapsFileResponse(res) {
  if (res.status === 200) {
    const pdoMapsFile = res.data;
    if (pdoMapsFile) {
      return pdoMapsFile;
    } else {
      return Promise.reject({
        errors: ["Invalid Fetch Pdo maps file response."]
      });
    }
  }
}

export function handleSavePdoFamiliesFileResponse(res) {
  if (res.status === 200) {
    const responseData = res.data;
    if (responseData) {
      return responseData;
    } else {
      return Promise.reject({
        errors: ["Invalid project save Pdo Families file response."]
      });
    }
  }
}

export function handleFetchPdoFamiliesFileResponse(res) {
  if (res.status === 200) {
    const pdoMapsFile = res.data;
    if (pdoMapsFile) {
      return pdoMapsFile;
    } else {
      return Promise.reject({
        errors: ["Invalid Fetch Pdo Families file response."]
      });
    }
  }
}

export function handleSaveProjectConfigFileResponse(res) {
  if (res.status === 200) {
    const responseData = res.data;
    if (responseData) {
      return responseData;
    } else {
      return Promise.reject({
        errors: ["Invalid project save Project Config file response."]
      });
    }
  }
}

export function handleFetchProjectConfigFileResponse(res) {
  if (res.status === 200) {
    const pdoMapsFile = res.data;
    if (pdoMapsFile) {
      return pdoMapsFile;
    } else {
      return Promise.reject({
        errors: ["Invalid Fetch Project Config file response."]
      });
    }
  }
}

export function handleProjectCreatedResponse(res) {
  if (res.status === 200) {
    const responseData = res.data;
    if (responseData) {
      return responseData;
    } else {
      return Promise.reject({
        errors: ["Invalid Project Created Response."]
      });
    }
  }
}

export function handleProjectDeletedResponse(res) {
  if (res.status === 200) {
    const responseData = res.data;
    if (responseData) {
      return responseData;
    } else {
      return Promise.reject({
        errors: ["Invalid Project Deleted Response."]
      });
    }
  }
}
export function handleErrorResponse(err) {
  if (!err.response) {
    return Promise.reject({ errors: ["Network errors."] });
  }
  const { errors, status } = err.response.data;
  if ([401, 403].indexOf(status) !== -1) {
    authenticationService.logout();
  }
  if (errors) {
    const validateResult = errorResponseSchema.validate(errors);
    if (validateResult.length === 0) {
      return Promise.reject({ errors });
    } else {
      return Promise.reject({ errors: ["Invalid errors response."] });
    }
  } else {
    return Promise.reject({ errors: ["Unknown errors response."] });
  }
}
