import React from "react";

import { Flex, Box, Text } from "rebass";

export default props => {
  const { tabs, tabSelectedIndex, setTabSelectedIndex } = props;

  const TopNavItem = props => {
    const { title, isActive, onClick, icon } = props;

    const TextStyleItem = (
      <Box
        onClick={onClick}
        sx={{
          mr: [3, 3, 4],
          pt: 1,
          pb: [2, 3, 3],
          borderBottom: isActive ? "2px solid" : "none",
          borderColor: isActive ? "rgba(255, 0, 0, 0.8)" : "transparent",
          ":hover": !isActive
            ? {
                cursor: "pointer",
                borderBottom: "2px solid grey"
              }
            : {
                cursor: "pointer"
              }
        }}
      >
        <Text as="h5" letterSpacing="0.5px" fontSize={["12px", "14px", "16px"]}>
          {title}
        </Text>
      </Box>
    );

    const IconStyleIcon = (
      <Flex
        //bg="#F1F2F2"
        bg="white"
        //bg="rgba(240,249,248)"
        onClick={onClick}
        alignItems="center"
        height={[30, 40, 40]}
        width={40}
        py={"auto"}
        mr={3}
        justifyContent="center"
        sx={{
          borderRadius: "60px",
          boxShadow: isActive && "2px 2px 3px 0px rgba(118,170,184, 0.6)",
          ":hover": !isActive
            ? {
                cursor: "pointer",
                boxShadow: "2px 2px 3px 0px rgba(184,120,164, 0.4)"
              }
            : {
                cursor: "pointer"
              }
        }}
      >
        {icon}
      </Flex>
    );
    return icon ? IconStyleIcon : TextStyleItem;
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      {tabs.map((tab, id) => {
        const isActive = tabSelectedIndex === id;
        return (
          <TopNavItem
            title={tab.title}
            icon={tab.icon}
            isActive={isActive}
            key={id}
            onClick={() => {
              setTabSelectedIndex(id);
              //ontabChanged(id);
            }}
          />
        );
      })}
    </Flex>
  );
};
