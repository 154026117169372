import xml2js from "xml2js";

const xmlParser = xml2js.parseString;
const toNumberString = (input = "") => {
  // Standard input string, 0x00, #x0012, to hexial number: 0x00123
  if (Number.isInteger(input)) {
    return input;
  }
  const prefix = input.substring(0, 2).toLowerCase();
  if (prefix === "0x" || prefix === "#x") {
    let suffix = input.substring(2).toUpperCase();
    return `0x${suffix}`;
  }
  return input;
};
const toPDOMap = (deviceObj, vendorName, vendorId) => {
  const rxPdoSource = deviceObj.RxPdo || [];
  const txPdoSource = deviceObj.TxPdo || [];
  let profileNo = 5001;

  if (deviceObj.Profile) {
    if (deviceObj.Profile[0].ChannelInfo) {
      profileNo = deviceObj.Profile[0].ChannelInfo[0].ProfileNo[0];
    } else
      profileNo = deviceObj.Profile[0]?.ProfileNo
        ? deviceObj.Profile[0]?.ProfileNo[0]
        : 0;
  }
  let deviceName;
  if (deviceObj["Name"].length === 1) {
    if (deviceObj["Name"][0]["_"]) {
      deviceName = deviceObj["Name"][0]["_"];
    } else {
      deviceName = deviceObj["Name"][0];
    }
  } else {
    deviceName = deviceObj["Name"][0]["_"];
  }
  let productCode = "";
  let revisionNo = "";
  if (deviceObj["Type"].length === 1) {
    productCode = toNumberString(deviceObj["Type"][0]["$"]["ProductCode"]);
    revisionNo = deviceObj["Type"][0]["$"]["RevisionNo"];
  }

  return {
    [deviceName]: {
      profileNo: parseInt(profileNo, 10),
      productCode,
      revisionNo,
      vendorName,
      vendorId: toNumberString(vendorId),
      RxPDO: rxPdoSource.map((rxPdo) => {
        return {
          index: toNumberString(
            rxPdo.Index[0]["_"] ? rxPdo.Index[0]["_"] : rxPdo.Index[0]
          ),
          name: rxPdo.Name[0]["_"] ? rxPdo.Name[0]["_"] : rxPdo.Name[0],
          sm:
            rxPdo["$"] && rxPdo["$"]["Sm"]
              ? parseInt(rxPdo["$"]["Sm"], 10)
              : -1,
          exclude: rxPdo.Exclude
            ? rxPdo.Exclude.map((item) => toNumberString(item))
            : [],
          entries: rxPdo.Entry
            ? rxPdo.Entry.map((entry) => ({
                index: toNumberString(
                  entry.Index[0]["_"] ? entry.Index[0]["_"] : entry.Index[0]
                ),

                subIndex: entry.SubIndex
                  ? toNumberString(entry.SubIndex[0])
                  : "0x00",
                dType: entry.DataType ? entry.DataType[0] : "null",
                //name: entry.Name ? entry.Name[0] : "null",
                name: entry.Name
                  ? entry.Name[0]
                    ? entry.Name[0]["_"]
                      ? entry.Name[0]["_"]
                      : entry.Name[0]
                    : "null"
                  : "null",
                bitLength: entry.BitLen ? parseInt(entry.BitLen[0], 10) : 8
              }))
            : []
        };
      }),

      TxPDO: txPdoSource.map((txPdo) => {
        return {
          index: (txPdo.Index[0]["_"]
            ? txPdo.Index[0]["_"]
            : txPdo.Index[0]
          ).replace("#", "0"),
          name: txPdo.Name[0]["_"] ? txPdo.Name[0]["_"] : txPdo.Name[0],
          sm:
            txPdo["$"] && txPdo["$"]["Sm"]
              ? parseInt(txPdo["$"]["Sm"], 10)
              : -1,
          exclude: txPdo.Exclude
            ? txPdo.Exclude.map((item) => toNumberString(item))
            : [],
          entries: txPdo.Entry
            ? txPdo.Entry.map((entry) => ({
                index: (entry.Index[0]["_"]
                  ? entry.Index[0]["_"]
                  : entry.Index[0]
                ).replace("#", "0"),
                subIndex: entry.SubIndex
                  ? toNumberString(entry.SubIndex[0])
                  : "0x00",
                dType: entry.DataType ? entry.DataType[0] : "null",
                name: entry.Name
                  ? entry.Name[0]
                    ? entry.Name[0]["_"]
                      ? entry.Name[0]["_"]
                      : entry.Name[0]
                    : "null"
                  : "null",
                bitLength: entry.BitLen ? parseInt(entry.BitLen[0], 10) : 8
              }))
            : []
        };
      })
    }
  };
};

export const parseEsiFile = (esiContent) => {
  return new Promise(function (resolve, reject) {
    xmlParser(esiContent, (err, esiObj) => {
      if (!err) {
        const vendorObj = {
          Name: esiObj["EtherCATInfo"]["Vendor"][0]["Name"]
            ? esiObj["EtherCATInfo"]["Vendor"][0]["Name"][0]
            : null,
          Id: esiObj["EtherCATInfo"]["Vendor"][0]["Id"]
            ? esiObj["EtherCATInfo"]["Vendor"][0]["Id"][0].replace("#", "0")
            : null
        };
        const deviceObjs =
          esiObj?.EtherCATInfo?.Descriptions[0]?.Devices[0]?.Device;
        const importantProperties = [
          "RxPdo",
          "Type",
          "TxPdo",
          "Profile",
          "Name",
          "Index"
        ];

        const pdoMaps = [];
        try {
          for (let i = 0; i < deviceObjs.length; i++) {
            const deviceProperties = Object.keys(deviceObjs[i]);
            // Remove unnecessary properties
            deviceProperties.forEach((property) => {
              if (!importantProperties.includes(property)) {
                delete deviceObjs[i][property];
              }
            });
            const pdoMap = toPDOMap(
              deviceObjs[i],
              vendorObj["Name"],
              vendorObj["Id"]
            );

            pdoMaps.push(pdoMap);
          }
          resolve(pdoMaps);
        } catch (err) {
          reject(err);
        }
      } else {
        reject({
          error: "XML file in wrong syntax."
        });
      }
    });
  });
};

// Main Function
export const processAcceptedFile = (acceptedFile) => {
  return new Promise(function (resolve, reject) {
    parseEsiFile(acceptedFile.content)
      .then(pdoMapsToPDOSelectOption)
      .then(({ pdoMaps, txPDOOptions, rxPDOOptions }) => {
        // delete file content because it not important;
        acceptedFile && delete acceptedFile["content"];
        resolve({
          [acceptedFile.name]: {
            ...acceptedFile,
            pdoMaps: [...pdoMaps],
            txPDOOptions,
            rxPDOOptions
          }
        });
      })
      .catch((error) => {
        reject({
          fileName: acceptedFile.name,
          ...error
        });
      });
  });
};

export const pdoMapsToPDOSelectOption = (pdoMaps) => {
  const toSelectOption = (pdoMap, pdo) => {
    const deviceName = Object.keys(pdoMap)[0];
    const productCode = pdoMap[deviceName]["productCode"];

    const revisionNo = toNumberString(pdoMap[deviceName]["revisionNo"]);

    const vendorName = pdoMap[deviceName]["vendorName"];
    const vendorId = pdoMap[deviceName]["vendorId"];
    const profileNo = pdoMap[deviceName]["profileNo"];
    const pdoGroup = `${productCode}-${revisionNo}`;

    const label = pdo["name"];
    const pdoIndex = pdo["index"];
    const sm = pdo["sm"];

    return {
      label: `${label} [${pdoIndex}]`, //label + `[$]`,
      value: "rx-Index: " + pdoIndex,
      profileNo,
      productCode,
      deviceName,
      vendorName,
      vendorId,
      sm,
      pdoGroup,
      pdo
    };
  };

  return new Promise(function (resolve, reject) {
    // resolve(pdoMaps);
    let rxPDOOptions = [];
    let txPDOOptions = [];

    try {
      pdoMaps &&
        pdoMaps.forEach((pdoMap) => {
          // PDO Map included RxPDO, TxDO
          const deviceName = Object.keys(pdoMap)[0];
          let label = deviceName && deviceName.substring(0, 20);
          if (deviceName.length > 20) {
            label = label + "...";
          }
          //label = label + pdoMap.pro

          if (pdoMap) {
            label += ` [${pdoMap[deviceName].productCode}-${toNumberString(
              pdoMap[deviceName].revisionNo
            )}]`;
          }

          const rxPDOOption = {
            //label: deviceName && deviceName.split(5),
            label: label,
            options: pdoMap[deviceName]["RxPDO"].map((pdo, orderIndex) => {
              return toSelectOption(pdoMap, pdo);
            })
          };

          const txPDOOption = {
            label: label,
            moreInfo: "justTesting",
            options: pdoMap[deviceName]["TxPDO"].map((pdo, orderIndex) => {
              return toSelectOption(pdoMap, pdo);
            })
          };
          rxPDOOptions.push(rxPDOOption);
          txPDOOptions.push(txPDOOption);
        });

      resolve({
        pdoMaps,
        rxPDOOptions,
        txPDOOptions
      });
    } catch (err) {
      reject({
        error: "Invalid esi file content.",
        detail: err
      });
    }
  });
};

export const toPdoSelectOption = (storagedFile) => {
  const toSelectOption = (pdoMap, pdo) => {
    const deviceName = Object.keys(pdoMap)[0];
    const productCode = pdoMap[deviceName]["productCode"];

    const revisionNo = toNumberString(pdoMap[deviceName]["revisionNo"]);
    const vendorName = pdoMap[deviceName]["vendorName"];
    const vendorId = pdoMap[deviceName]["vendorId"];
    const profileNo = pdoMap[deviceName]["profileNo"];
    const pdoGroup = `${productCode}-${revisionNo}`;

    const label = pdo["name"];
    const pdoIndex = pdo["index"];
    const sm = pdo["sm"];

    return {
      label: `${label} [${pdoIndex}]`, //label + `[$]`,
      value: "rx-Index: " + pdoIndex,
      profileNo,
      productCode,
      deviceName,
      vendorName,
      vendorId,
      sm,
      pdoGroup,
      pdo
    };
  };

  return new Promise(function (resolve, reject) {
    const fileName = Object.keys(storagedFile)[0];
    const pdoMaps = storagedFile[fileName]["pdoMaps"];
    // resolve(pdoMaps);
    let rxPDOOptions = [];
    let txPDOOptions = [];

    try {
      pdoMaps &&
        pdoMaps.forEach((pdoMap) => {
          // PDO Map included RxPDO, TxDO
          const deviceName = Object.keys(pdoMap)[0];
          const rxPDOOption = {
            label: deviceName,
            options: pdoMap[deviceName]["RxPDO"].map((pdo, orderIndex) => {
              return toSelectOption(pdoMap, pdo);
            })
          };

          const txPDOOption = {
            label: deviceName,
            options: pdoMap[deviceName]["TxPDO"].map((pdo, orderIndex) => {
              return toSelectOption(pdoMap, pdo);
            })
          };
          rxPDOOptions.push(rxPDOOption);
          txPDOOptions.push(txPDOOption);
        });

      resolve({
        rxPDOOptions,
        txPDOOptions
      });
    } catch (err) {
      reject({
        error: "Invalid esi file content.",
        detail: err
      });
    }
  });
};
