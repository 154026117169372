import React, { useState, useLayoutEffect } from "react";
import { Text, Flex, Box, Image } from "rebass";
import { Input } from "@rebass/forms";
import { Select } from "@rebass/forms";
import Button from "@atlaskit/button";
import bannedImg from "../../assets/images/banned-small.png";

import BitbucketCloneIcon from "@atlaskit/icon/glyph/bitbucket/clone";
import CrossIcon from "@atlaskit/icon/glyph/cross";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const SlaveItem = props => {
  const {
    slaveInfo,
    updateSlave,
    slaveIndex,
    itemDeletable,
    onDeleteItem,
    onCloneItem,
    onClick,
    isActive
  } = props;

  const { pdoMapFamilies } = props;
  const familyIndexs = Object.keys(pdoMapFamilies).map(key =>
    parseInt(key, 10)
  );

  const [selectedFamilyIndex, setSelectedFamilyIndex] = useState(
    slaveInfo && slaveInfo.familyIndex
  );

  let familyRemoved = false;
  let familyInfo = {};

  if (Object.keys(slaveInfo).length > 0) {
    const { familyIndex } = slaveInfo;
    if (familyIndexs.indexOf(familyIndex) === -1) {
      familyRemoved = true;
    } else {
      familyInfo = pdoMapFamilies[familyIndex];
    }
  }

  const options =
    familyIndexs &&
    familyIndexs.map(id => <option key={`opt-${id}`}>{id}</option>);

  if (familyRemoved === true) {
    options.push(
      <option disabled key={`opt-${slaveInfo.familyIndex}`}>
        {slaveInfo.familyIndex}
      </option>
    );
  }

  const info = { ...familyInfo, alias: 0, copies: 1 };

  if (
    Object.keys(pdoMapFamilies).length > 0 &&
    Object.keys(slaveInfo).length === 0
  ) {
    // Auto add slave item
    const firstFamilyIndex = parseInt(Object.keys(pdoMapFamilies)[0], 10);
    updateSlave(slaveIndex, {
      familyIndex: firstFamilyIndex,
      //pos: slaveIndex,
      pos: 0,
      alias: 0,
      copies: 1
    });
  }

  const onFamilyChanged = famId => {
    setSelectedFamilyIndex(parseInt(famId, 10));
    updateSlave(slaveIndex, {
      ...slaveInfo,
      familyIndex: parseInt(famId, 10)
    });
  };

  const onAliasChanged = e => {
    e.preventDefault();
    updateSlave(slaveIndex, {
      ...slaveInfo,
      alias: parseInt(e.target.value, 10)
    });
  };
  const onCopiesChanged = e => {
    e.preventDefault();

    updateSlave(slaveIndex, {
      ...slaveInfo,
      copies: parseInt(e.target.value, 10)
    });
  };

  const [windowWidth] = useWindowSize();

  return (
    <Flex
      bg={
        familyRemoved
          ? "rgba(125, 20, 20,0.4)"
          : isActive
          ? "#212936"
          : "#212936"
      }
      height="60px"
      alignItems="center"
      sx={{
        fontFamily: "Fjalla One, sans-serif",
        ":hover": {
          cursor: "pointer"
        },
        borderTopLeftRadius: "40px",
        borderBottomLeftRadius: "40px",
        boxShadow: isActive && "2px 3px 4px 0px rgba(222,53,11, 0.6)"
      }}
      onClick={onClick}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        ml={2}
        bg={isActive ? "#FF5630" : "#FF5630"}
        width={["60px", "55px", "55px"]}
        height="50px"
        sx={{
          borderRadius: "100px"
        }}
      >
        <Text fontSize="25px" color="white" fontWeight="600">
          {slaveIndex}
        </Text>
      </Flex>

      <Flex ml={2} flexDirection={["column", "row", "row"]} width={1}>
        <Flex
          py={1}
          color="#52AE91"
          width={[1, 2 / 3, 2 / 3]}
          justifyContent={["left", "center"]}
        >
          <Flex
            flexDirection="column"
            alignItems={["right", "center"]}
            justifyContent="center"
          >
            {windowWidth >= 640 && <Text letterSpacing="1.1px">NAME</Text>}
            <Text
              textAlign="left"
              px={[0, 3, 3]}
              fontSize={[10, 11, 14]}
              letterSpacing="0.5px"
              sx={{
                whiteSpace: "wrap",

                textOverflow: "ellipsis"
              }}
            >
              {info.deviceName}
            </Text>
          </Flex>
        </Flex>

        <Flex width={1}>
          {windowWidth >= 640 && (
            <Flex
              justifyContent="center"
              height={"24px"}
              my="auto"
              sx={{
                transform: "rotate(-90deg)"
              }}
            >
              <Text
                color="#A3A6AC"
                fontSize={[10, 12, 14]}
                sx={{
                  borderBottom: "1px solid red"
                }}
              >
                CONFIGS
              </Text>
            </Flex>
          )}

          <Flex px={2} color="white" alignItems="center">
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize={["10px", "12px", "14px"]} letterSpacing="0.6px">
                FAMILY
              </Text>
              <Box width={40} height={20}>
                <Select
                  id="family"
                  name="family"
                  //value={parseInt(selectedFamilyIndex, 10)}
                  value={slaveInfo.familyIndex}
                  fontSize={["12px", "14px", "16px"]}
                  onChange={e => {
                    onFamilyChanged(e.target.value);
                  }}
                  sx={{
                    padding: 0,
                    paddingLeft: "6px",
                    paddingBottom: "2px",
                    border: "none",
                    color: "#00875A",
                    fontFamily: "inherit",
                    fontWeight: "bold"
                  }}
                >
                  {options}
                </Select>
              </Box>
            </Flex>
          </Flex>

          <Flex px={2} color="white" my="auto" alignItems="center">
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize={["10px", "12px", "14px"]} letterSpacing="1.1px">
                ALIAS
              </Text>
              <Input
                type="number"
                value={slaveInfo.alias}
                onChange={onAliasChanged}
                fontSize={["12px", "14px", "16px"]}
                min={0}
                style={{
                  width: "40px",
                  background: "transparent",
                  border: "none",
                  color: "white",
                  textAlign: "center",
                  fontFamily: "inherit",
                  paddingTop: "2px",
                  paddingBottom: "0px"
                }}
              />
            </Flex>
          </Flex>

          <Flex px={2} color="white" alignItems="center">
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize={["10px", "12px", "14px"]} letterSpacing="1.1px">
                POS
              </Text>
              <Text fontSize={["12px", "14px", "16px"]}>{slaveInfo.pos}</Text>
            </Flex>
          </Flex>

          <Flex px={2} color="white" alignItems="center">
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize={["10px", "12px", "14px"]} letterSpacing="1.1px">
                COPIES
              </Text>
              <Input
                type="number"
                value={slaveInfo.copies}
                onChange={onCopiesChanged}
                fontSize={["12px", "14px", "16px"]}
                min={1}
                style={{
                  width: "60px",
                  background: "transparent",
                  border: "none",
                  color: "white",
                  textAlign: "center",
                  fontFamily: "inherit",
                  paddingTop: "2px",
                  paddingBottom: "0px"
                }}
              />
            </Flex>
          </Flex>

          {familyRemoved && (
            <Flex flexDirection="column">
              <Image src={bannedImg} />f
              <Text
                fontSize="9px"
                fontWeight="400"
                sx={{ fontStyle: "italic" }}
              >
                *Family with id [{slaveInfo.familyIndex}] has ben removed
              </Text>
            </Flex>
          )}
          <Box mx={"auto"} />
          <Flex
            justifyContent="center"
            height={"24px"}
            my="auto"
            sx={{
              transform: "rotate(-90deg)"
            }}
          >
            <Text
              color="#A3A6AC"
              fontSize={[10, 12, 14]}
              sx={{
                borderBottom: "1px solid red"
              }}
            >
              ACTIONS
            </Text>
          </Flex>

          <Flex alignItems="center" mr={2}>
            <Button
              style={{
                margin: "2px"
              }}
              appearance="warning"
              onClick={() => {
                onCloneItem(slaveIndex);
              }}
              iconBefore={<BitbucketCloneIcon size="small" />}
            />
            <Button
              style={{
                margin: "2px"
              }}
              //onClick={() => addSlave(slaveInfo)}
              appearance="danger"
              isDisabled={!itemDeletable}
              onClick={() => {
                onDeleteItem && onDeleteItem(slaveIndex);
              }}
              iconBefore={<CrossIcon size="small" />}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SlaveItem;
