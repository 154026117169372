import React from "react";
import { Flex, Box, Text } from "theme-ui";

const PDOMaterialTableTitle = props => {
  const { caption, pdoName, pdoIndex } = props;
  return (
    <Flex
      alignItems="center"
      sx={{
        borderRadius: "30px"
      }}
    >
      <Box flex={1} />
      <Flex alignItems="center">
        <Text
          fontSize={["12px", "14px", "14px"]}
          px={14}
          py={1}
          mx={2}
          sx={{
            borderRadius: "20px",
            border: "1px solid"
          }}
        >
          {pdoIndex}
        </Text>
        <Text
          fontSize={["12px", "14px", "14px"]}
          px={14}
          py={1}
          sx={{
            borderRadius: "20px",
            border: "1px solid"
          }}
        >
          {pdoName}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PDOMaterialTableTitle;
