import React from "react";
import { Box, Flex, Text } from "rebass";

import CrossIcon from "@atlaskit/icon/glyph/cross";
import FileIcon from "@atlaskit/icon/glyph/file";
import IconExplorerLink from "./atoms/IconExplorerLink";
import prettyBytes from "pretty-bytes";

const AcceptedFileItem = props => {
  const { file, name, handleDeleteFileByName } = props;
  return (
    <Box
      px={2}
      mt={[2, 3, 3]}
      mx={3}
      css={{
        borderRadius: "4px",
        background: "white",
        boxShadow: "rgba(12, 12, 13, 0.1) 0px 0px 8px 0px"
      }}
    >
      <Flex
        p={["8px", "8px", "12px"]}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box py="auto" height="100%">
          <FileIcon size="medium" />
        </Box>

        <Flex flex={1} flexDirection="column" mx={[2, 3, 3]}>
          <Text
            as="h5"
            fontSize={["12px", "16px", "16px"]}
            sx={{
              wordWrap: "break-word",
              textTransform: "none"
            }}
          >
            {file.name}
          </Text>
          <Text
            as="h6"
            pt={1}
            fontSize={["10px", "13px", "13px"]}
            sx={{
              textTransform: "none",
              color: "grey"
            }}
          >
            {prettyBytes(file.size)}
          </Text>
        </Flex>

        <IconExplorerLink
          onClick={() => {
            handleDeleteFileByName(name);
          }}
        >
          <CrossIcon size="small" />
        </IconExplorerLink>
      </Flex>
    </Box>
  );
};

export default AcceptedFileItem;
