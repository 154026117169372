import React, { useState } from "react";
import { Flex, Text, Box } from "theme-ui";
import { PanelStateless } from "@atlaskit/panel";

import SlaveListDisplay from "../components/molecules/SlaveListDisplay";

const SlavesPanel = props => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { pdoMapFamilies, slaveInfo, viewButton, sendButton } = props;

  return (
    <div id="panel">
      <PanelStateless
        isExpanded={isExpanded}
        onChange={state => setIsExpanded(state)}
        header={
          <Flex
            sx={{
              justifyContent: "space-between"
            }}
          >
            <style jsx>
              {`
                #panel span {
                  width: 100%;
                }
              `}
            </style>
            <Text
              className="expanded"
              as="h3"
              sx={{
                pb: 1,
                borderBottom: isExpanded ? "solid 2px red" : "none"
              }}
            >
              SLAVES
            </Text>
            <Flex>
              <Box
                sx={{
                  mr: 3
                }}
              >
                {viewButton}
              </Box>
              <Box sx={{ mr: 3 }}>{sendButton}</Box>
            </Flex>
          </Flex>
        }
      >
        <SlaveListDisplay
          pdoMapFamilies={pdoMapFamilies}
          slaveInfo={slaveInfo}
        />
      </PanelStateless>
    </div>
  );
};

export default SlavesPanel;
