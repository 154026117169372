import React from "react";
import { Flex, Text, Box, Link } from "theme-ui";
import AnimatedFileList from "./AnimatedFileList";

const FileStoragedScreen = props => {
  const { changeTab } = props;
  const { storagedFileList, handleDeleteStoragedFile } = props;
  return (
    <Flex
      sx={{
        flexDirection: "column",
        bg: "#e3e3e3",
        pt: 2,
        mx: [1, 3, 3],
        mb: 2,
        height: ["200px", "auto"]
      }}
    >
      <Text as="h2" fontSize={["16px", "20px", "22px"]} ml={3}>
        Uploaded Files
      </Text>
      <Flex
        sx={{
          flex: 1,
          overflow: "auto",
          flexDirection: "column"
        }}
      >
        <AnimatedFileList
          fileList={storagedFileList}
          handleDeleteFileByName={handleDeleteStoragedFile}
        />
      </Flex>

      <Box
        sx={{
          pt: [2, 3, 3],
          px: 2,
          mx: "auto",
          mt: [2, 2, "auto"],
          mb: [2, 3, 3]
        }}
      >
        <Text
          as="h5"
          color="green"
          textAlign="center"
          sx={{
            fontStyle: "italic"
          }}
        >
          ** You should on{" "}
          <Link
            onClick={e => {
              e.preventDefault();
              changeTab(1);
            }}
          >
            Family Editor
          </Link>{" "}
          tab now!! **
        </Text>
      </Box>
    </Flex>
  );
};

export default FileStoragedScreen;
