import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticationService } from "../../services/authenticationService";
const AuthRedirectRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (currentUser) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard"
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default AuthRedirectRoute;
