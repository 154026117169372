import React from "react";
import { Flex, Box, Button, Text } from "rebass";

import AddCircleIcon from "@atlaskit/icon/glyph/add-circle";
import { useDropzone } from "react-dropzone";

import UseAnimations from "react-useanimations";

const DragAndDropEsiFileScreen = (props) => {
  return (
    <Flex
      px={3}
      sx={{
        flexDirection: "column",
        flex: 1
      }}
    >
      <DragDropScreen {...props} />
      <Flex>
        <Text
          as="h4"
          sx={{
            textAlign: "center",
            fontStyle: "italic",
            fontWeight: 300
          }}
        >
          Accept file types: .esi, .xml
        </Text>
        <Box mx="auto" />
      </Flex>
    </Flex>
  );
};

const DragDropScreen = (props) => {
  const { onDrop } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".xml, .esi"
  });

  const DragActiveScreen = (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <UseAnimations animationKey="arrowUp" size={56} />
      <Text pb={2} pt={24} as="h3">
        Drop file for uploading
      </Text>
    </Flex>
  );
  const DragInactiveScreen = (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <AddCircleIcon size="xlarge" />
      <Text pb={2} pt={24} as="h3">
        Drag and drop files
      </Text>
      <Text pb={24}>Or click to send up</Text>
      <Button
        //bg="#0060DF"
        bg="#172B4D"
        mt={4}
        css={{
          ":hover": {
            backgroundColor: "#003eaa"
          },
          fontSize: "16px"
        }}
      >
        Select file to upload
      </Button>
    </Flex>
  );
  return (
    <Flex
      bg="#F1F5FD"
      {...getRootProps()}
      my={3}
      pb={[3, 3, 0]}
      sx={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        border: "2px",
        borderRadius: 4,
        borderStyle: "dashed",
        borderColor: "#D0D0D1"
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? DragActiveScreen : DragInactiveScreen}
    </Flex>
  );
};
export default DragAndDropEsiFileScreen;
