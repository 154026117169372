import React, { Fragment, useState } from "react";

const Tooltip = props => {
  const style = `
  .tooltip {
    position: relative;
    height: 100%
  }
  
  .tooltip-content {
    position: absolute;
    background-color: #3C444C;
    text-align: center;
    color: #fff;
    transform: translateX(-50%);
    white-space: nowrap;
    border-radius: 4px;
    padding: 12px 16px;
  }
  
  .tooltip-content:before {
    content: "";
    position: absolute;
  }
  
  /* POSITION TOP */
  .tooltip-content.top {
    bottom: 100%;
    left: 50%;
    margin-bottom: 10px;
  }
  .tooltip-content.top:before {
    top: 100%;
    left: 50%;
    margin-left: -4px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #3C444C;
  }`;

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    //<style jsx>`{style}`</style>
    <Fragment>
      <style jsx>`{style}`</style>
      <div
        className="tooltip"
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {showTooltip === true && (
          <div className="tooltip-content top">{props.content}</div>
        )}
        {props.children}
      </div>
    </Fragment>
  );
};

export default Tooltip;
