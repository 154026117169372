import React, { useState, useEffect } from "react";

import { Flex, Box } from "theme-ui";
import AddCircle from "@atlaskit/icon/glyph/add";
import AddCircleIcon from "@atlaskit/icon/glyph/add-circle";

import PDOFamiliesPanel from "../../components/PDOFamiliesPanel";

import PDOFamilyEditorScreen from "../../components/PDOFamilyEditorScreen";
import NoDataScreen from "../../components/NoDataScreen";

import { storagedFilesService } from "../../services/storagedFilesService";
import { pdoMapFamiliesService } from "../../services/pdoMapFamiliesService";
import { authenticationService } from "../../services/authenticationService";

import _ from "lodash";
import { useParams } from "react-router-dom";
import { notifyResponseError } from "../toast-notifiers";

const FamilyEditorTab = (props) => {
  const { rxPDOOptions, txPDOOptions } = storagedFilesService.pdoSelectOptions;
  const [pdoMapFamilies, setPdoMapFamilies] = useState([]);

  const [selectedPDOFamilyId, setSelectedPDOFamilyId] = useState();
  let [mustSaveFile, setMustSaveFile] = useState(false);
  const { projectId } = useParams();

  const { changeTab } = props;
  const hasData = rxPDOOptions.length + rxPDOOptions.length > 0;

  const delayedSavePdoFamiliesFiles = _.debounce((_projectId) => {
    return pdoMapFamiliesService
      .savePDOMapFamilyFile(_projectId)
      .then((resData) => {})
      .catch((err) => {
        notifyResponseError(err.errors[0]);
        //notifyResponseError("Test here here");
      });
  }, 2000);

  useEffect(() => {
    let subscription;
    if (authenticationService.userAuhthenticated) {
      pdoMapFamiliesService
        .fetchPDOMapFamilyFile(projectId)
        .then((families) => {
          if (families.length > 0) {
            pdoMapFamiliesService.setPdoFamilies(families);
            subscription = pdoMapFamiliesService.subscribe(setPdoMapFamilies);
            setSelectedPDOFamilyId(families[0].id);
            setMustSaveFile(true);
          }
        })
        .catch((err) => {
          notifyResponseError(err.errors[0]);
        });
    } else {
      subscription = pdoMapFamiliesService.subscribe(setPdoMapFamilies);
    }
    return () => {
      subscription && subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (mustSaveFile) {
      delayedSavePdoFamiliesFiles(projectId);
    }
  }, [pdoMapFamilies, delayedSavePdoFamiliesFiles, projectId, mustSaveFile]);

  const addNewPDOMapFamily = () => {
    pdoMapFamiliesService.addNewPDOMapFamily().then((newFamily) => {
      setSelectedPDOFamilyId(newFamily.id);
    });
  };

  const removePDOMapFamily = (familyId) => {
    pdoMapFamiliesService
      .removePDOMapFamily(familyId)
      .then((pdoMapFamilies) => {
        if (familyId === selectedPDOFamilyId) {
          if (pdoMapFamilies.length > 1) {
            const newSelectedFamilyId = pdoMapFamilies[0].id;

            setSelectedPDOFamilyId(newSelectedFamilyId);
          }
        }
      });
  };

  const updatePDOMapFamilies = (family) => {
    pdoMapFamiliesService.updatePDOMapFamilies(family, selectedPDOFamilyId);
  };

  const familyList = pdoMapFamilies.map((familyIndex) => {
    return {
      label: "family",
      value: familyIndex,
      index: familyIndex
    };
  });

  const emptyPDOMapFamily = () => {
    pdoMapFamiliesService.emptyPDOMapFamily();
  };

  const onFamilySelected = (familyId) => {
    setSelectedPDOFamilyId(familyId);
    //setMustSaveFile(true);
  };

  const familyNotAvailable = pdoMapFamilies.length === 0;
  const selectedFamilyPosition = pdoMapFamilies.findIndex(
    (family) => family.id === selectedPDOFamilyId
  );
  const selectedPDOFamily = pdoMapFamilies[selectedFamilyPosition];
  return (
    <Box p={3} my={"auto"} mb={5}>
      <Flex
        sx={{
          py: 4,
          px: "auto",
          width: "100%",
          minHeight: 468,
          boxShadow:
            "rgba(34, 0, 51, 0.04) 0px 12px 18px 2px, rgba(7, 48, 114, 0.12) 0px 6px 22px 4px, rgba(14, 13, 26, 0.12) 0px 6px 10px -4px",
          borderRadius: "16px",
          flexWrap: "wrap"
        }}
      >
        {hasData ? (
          familyNotAvailable ? (
            <Flex
              sx={{
                m: "auto",
                p: 3,
                bg: "#172B4D",
                cursor: "pointer",
                borderRadius: "50%",
                ":hover": {
                  boxShadow:
                    "rgba(34, 0, 51, 0.04) 0px 12px 18px 2px, rgba(7, 48, 114, 0.12) 0px 6px 22px 4px, rgba(14, 13, 26, 0.12) 0px 6px 10px -4px"
                }
              }}
              onClick={() => {
                addNewPDOMapFamily();
              }}
            >
              <AddCircle size="xlarge" primaryColor={"white"} />
            </Flex>
          ) : (
            <Flex
              sx={{
                flexDirection: "column",
                flex: 1,
                mx: 2
              }}
            >
              <PDOFamiliesPanel
                pdoMapFamilies={pdoMapFamilies}
                list={familyList}
                selectedPDOFamily={selectedPDOFamily}
                onAddNewPDOFamily={addNewPDOMapFamily}
                onRemovePDOFamily={removePDOMapFamily}
                onPDOFamilySelected={onFamilySelected}
                selectedPDOFamilyId={selectedPDOFamilyId}
                onEmptyList={emptyPDOMapFamily}
              />

              <Box
                sx={{
                  pt: 3
                }}
              >
                {selectedPDOFamily &&
                  Object.keys(selectedPDOFamily).length > 0 && (
                    <PDOFamilyEditorScreen
                      rxPDOOptions={[...rxPDOOptions]}
                      txPDOOptions={[...txPDOOptions]}
                      pdoFamily={selectedPDOFamily}
                      updateFamily={updatePDOMapFamilies}
                    />
                  )}
              </Box>
            </Flex>
          )
        ) : (
          <NoDataScreen changeTab={changeTab} />
        )}
      </Flex>
    </Box>
  );
};

export default FamilyEditorTab;
