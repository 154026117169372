import React from "react";
import ControllableItem from "./atoms/ControllableItem";

const PDOMapFamilyItem = props => {
  const {
    isActive,
    onPDOFamilySelected,
    onRemovePDOFamily,
    familyId,
    itemIndex
  } = props;
  return (
    <ControllableItem
      color={isActive ? "#ffffff" : "default"}
      bg={isActive ? "#16294C" : "#E6E6E6"}
      mx={[1, 2, 2]}
      onClickItem={() => {
        onPDOFamilySelected && onPDOFamilySelected(familyId);
      }}
      onDeleteItem={() => {
        onRemovePDOFamily && onRemovePDOFamily(familyId);
      }}
      item={{ label: "Family", value: itemIndex }}
    />
  );
};

export default PDOMapFamilyItem;
