import React from "react";
import { Flex } from "theme-ui";
import AddItemIcon from "@atlaskit/icon/glyph/add-item";
import ImageBorderIcon from "@atlaskit/icon/glyph/image-border";
import Clickable from "./atoms/Clickable";

const FamilyControlPanel = props => {
  const { onAddNewPDOFamily, isListNotEmpty, setIsDeleteAllModalOpen } = props;

  return (
    <Flex
      sx={{
        flexDirection: "column",
        pl: [1, 2, 2],
        alignItems: "center",
        justifyContent: "top"
      }}
    >
      <Clickable
        onClick={() => {
          onAddNewPDOFamily && onAddNewPDOFamily();
        }}
      >
        <AddItemIcon size="medium" />
      </Clickable>
      {isListNotEmpty && (
        <Clickable
          sx={{
            ":hover": {
              borderRadius: "50px",
              boxShadow: "0px 0px 12px 1px rgba(0, 0, 0, 0.41)"
            }
          }}
          py={1}
          px={1}
          onClick={() => {
            //onEmptyList && onEmptyList();
            setIsDeleteAllModalOpen(true);
          }}
        >
          <ImageBorderIcon size="small" />
        </Clickable>
      )}
    </Flex>
  );
};
export default FamilyControlPanel;
