import React from "react";
import Clickable from "./Clickable";
import ValueDisplay from "./ValueDisplay";

import { Flex, Box } from "rebass";
import CloseIcon from "@atlaskit/icon/glyph/editor/close";
//import Tooltip from '@atlaskit/tooltip';
import Tooltip from "./Tooltip";

const ControllableItem = props => {
  const { item, onClickItem, onDeleteItem } = props;

  return (
    <Flex {...props}>
      {item && (
        <ValueDisplay
          label={item.label}
          value={item.value}
          onClick={onClickItem}
        />
      )}

      <Clickable
        onDoubleClick={onDeleteItem}
        padding={[0, 1, 1]}
        alignItems="center"
        color="red"
        bg="#E6E6E6"
        sx={{
          ":hover": {
            boxShadow: "2px 2px 5px 0px rgba(250, 165, 165, 0.6)",
            color: "rgb(248, 40, 40)",
            background: "rgba(248, 180, 180)"
          }
        }}
      >
        <Tooltip content={" Double click to delete item."}>
          <Flex height={"100%"} alignItems="center">
            <CloseIcon size="small" />
          </Flex>
        </Tooltip>
      </Clickable>
    </Flex>
  );
};

export default ControllableItem;
