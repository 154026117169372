import React, { useState } from "react";
import { Flex, Text } from "theme-ui";
import PDOMapFamilyList from "./PDOMapFamilyList";
import FamilyDeleteNotification from "./FamilyDeleteNotification";
import FamilyInfoDisplay from "./FamilyInfoDisplay";
import FamilyControlPanel from "./FamilyControlPanel";
const PDOFamiliesPanel = props => {
  const {
    pdoMapFamilies,
    onAddNewPDOFamily,
    onRemovePDOFamily,
    onPDOFamilySelected,
    selectedPDOFamilyId,
    onEmptyList
  } = props;

  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);

  const isListNotEmpty = pdoMapFamilies && pdoMapFamilies.length > 0;

  const selectedFamilyPosition = pdoMapFamilies.findIndex(
    family => family.id === selectedPDOFamilyId
  );
  const selectedPDOFamily = pdoMapFamilies[selectedFamilyPosition];

  return (
    <Flex
      sx={{
        pb: 3,
        flexDirection: "column",
        ml: 3,
        borderBottom: "1.5px solid #20242A"
      }}
    >
      <FamilyDeleteNotification
        isDeleteAllModalOpen={isDeleteAllModalOpen}
        setIsDeleteAllModalOpen={setIsDeleteAllModalOpen}
        onEmptyList={onEmptyList}
      />
      <Text as="h2" pb={3}>
        PDO Families
      </Text>
      <Flex
        sx={{
          flexDirection: ["column", "column", "row"]
        }}
      >
        {selectedPDOFamily && (
          <Flex
            sx={{
              minWidth: "320px"
            }}
          >
            <FamilyInfoDisplay selectedPDOFamily={selectedPDOFamily} />
          </Flex>
        )}

        <Flex
          sx={{
            py: 2,
            mr: 2,
            boxShadow: "2px 2px 5px 0px rgba(12,16,77, 0.5)"
          }}
        >
          <FamilyControlPanel
            onAddNewPDOFamily={onAddNewPDOFamily}
            isListNotEmpty={isListNotEmpty}
            setIsDeleteAllModalOpen={setIsDeleteAllModalOpen}
          />

          <PDOMapFamilyList
            pdoMapFamilies={pdoMapFamilies}
            selectedPDOFamilyId={selectedPDOFamilyId}
            onPDOFamilySelected={onPDOFamilySelected}
            onRemovePDOFamily={onRemovePDOFamily}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PDOFamiliesPanel;
