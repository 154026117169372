import React from "react";
import { Flex, Box, Text, Image, Link } from "theme-ui";
import NoDataImage from "../assets/images/pouch-icon-24-small.png";

const NoDataScreen = props => {
  const { changeTab } = props;
  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        mx: "auto"
      }}
    >
      <Box height="140px" width="140px">
        <Image src={NoDataImage} />
      </Box>
      <Box
        sx={{
          py: "auto"
        }}
      >
        <Text
          as="h1"
          sx={{
            py: 3,
            textAlign: "center"
          }}
        >
          Opp, there is no data...
        </Text>
        <Text
          as="h4"
          sx={{
            textAlign: "center"
          }}
        >
          Please select{" "}
          <Link
            to="/"
            onClick={() => {
              changeTab && changeTab(0);
            }}
          >
            Upload Files
          </Link>{" "}
          tab for uploading your esi files.
        </Text>
      </Box>
    </Flex>
  );
};

export default NoDataScreen;
