import styled from "styled-components";
import { Flex } from "rebass";

/* -- Version 1 ---
const Clickable = styled(Box)`
  &,
  &:hover,
  &:active,
  &:focus {
    border-radius: ${props => (props.rounded ? 50 : gridSize() / 2)}px;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    padding: 4px;
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.41);
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.41);
  }

  background: ${props => (props.active ? colors.N300 : "#fafafa")};
`;
*/
const Clickable = styled(Flex)`
  &,
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    cursor: pointer;
    line-height: 0;
  }

  &:hover {
    ${props => {
      return (
        props.sx ||
        `WebkitBoxShadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.41);
          MozBoxShadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.41);
          box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.41);          
        `
      );
    }}
  }
`;
export default Clickable;
