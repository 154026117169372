import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "theme-ui";

import Button from "@atlaskit/button";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";

import ProjectInfoPanel from "../ProjectInfoPanel";
import SlavesPanel from "../SlavesPanel";
import WarningFlag from "../WarningFlag";
import JsonView from "react-json-view";

import LoadingOverlay from "react-loading-overlay";
import PropagateLoader from "react-spinners/PropagateLoader";

import toast from "../../utils/toaster";

import axios from "axios";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import SendIcon from "@atlaskit/icon/glyph/send";
import exportResult from "../../utils/exportResult";

import { pdoMapFamiliesService } from "../../services/pdoMapFamiliesService";

import { projectConfigService } from "../../services/projectConfigService";
import { authenticationService } from "../../services/authenticationService";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { notifyResponseError } from "../toast-notifiers";

const SlaveEditorTab = (props) => {
  const { changeTab } = props;

  const [projectConfig, setProjectConfig] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [mustSaveFile, setMustSaveFile] = useState(false);
  const [result, setResult] = useState();
  const [downloadFileContent, setDownloadFileContent] = useState();
  const { projectId } = useParams();

  const [settings, setSettings] = useState({
    codeGenServerIP: "192.168.0.5",
    codeGenServerPORT: 5001
  });

  const pdoMapFamilies = pdoMapFamiliesService.currentPdoMapFamiliesValue;
  const projectInfo = projectConfig && projectConfig["ProjectInfo"];
  const slaveInfo = projectConfig && projectConfig["SlaveInfo"];

  const delayedSaveProjectConfigFile = _.debounce((_projectId) => {
    return projectConfigService
      .saveProjectConfigFile(_projectId)
      .then((resData) => {})
      .catch((err) => {
        notifyResponseError(err.errors[0]);
      });
  }, 1800);

  useEffect(() => {
    let subscription;
    if (authenticationService.userAuhthenticated) {
      projectConfigService
        .fetchProjectConfigFile(projectId)
        .then((projectConfigContent) => {
          if (Object.keys(projectConfigContent).length > 0) {
            projectConfigService.setProjectConfig(projectConfigContent);
            subscription = projectConfigService.subscribe(setProjectConfig);
            setMustSaveFile(true);
          }
        })
        .catch((err) => {
          setMustSaveFile(false);
        });
    } else {
      subscription = projectConfigService.subscribe(setProjectConfig);
    }
    return () => {
      subscription && subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (mustSaveFile) projectId && delayedSaveProjectConfigFile(projectId);
  }, [projectConfig, delayedSaveProjectConfigFile, projectId, mustSaveFile]);

  const updateProjectInfo = (newInfo) => {
    projectConfigService.setProjectInfo(newInfo);
  };

  const closeModalDialog = () => {
    setIsModalOpen(false);
  };

  const actions = [
    { text: "Close", onClick: closeModalDialog },
    //{ text: "Download", onClick: downloadResult }
    {
      text: "Download",
      action: closeModalDialog,
      download: projectInfo && `${projectInfo[0].Name}.json`,
      //download: `'somename'.json`,
      href: `data: ${downloadFileContent}`
    }
  ];

  const onView = async (e) => {
    const result = exportResult(pdoMapFamilies, slaveInfo, projectInfo);
    const content =
      "text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(result, null, 2));

    setResult(result);
    setDownloadFileContent(content);
    setIsModalOpen(true);
  };

  const onSendFile = (e) => {
    const result = exportResult(pdoMapFamilies, slaveInfo, projectInfo);
    setResult(result);
    setIsSendingFile(true);
  };
  const isPDOMapFamilyAvailable = Object.keys(pdoMapFamilies).length > 0;

  if (isSendingFile) {
    let url = `http://${settings.codeGenServerIP}:${settings.codeGenServerPORT}/api`;

    toast.notify(
      <Flex alignItems="center">
        <Box>
          <SendIcon size="large" />
        </Box>
        <Text as="h5" pl={2} fontWeight={500}>
          {`File is sending to ${url}...`}
        </Text>
      </Flex>,
      { duration: 1200 }
    );

    axios(url, {
      //withCredentials: false,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      data: result
    })
      .then((res) => {
        const { response, message } = res.data;
        if (response !== null && message !== null) {
          if (response === "success") {
            toast.notify.success({
              icon: <DownloadIcon size="large" />,
              message
            });
          } else if (response === "error") {
            toast.notify.error({ message });
          }
        }
      })
      .catch((error) => {
        toast.notify.error({
          message: "You have problem with network to server."
        });
      })
      .finally(() => {
        setIsSendingFile(false);
      });
  }

  const viewButton = (
    <Button
      appearance="default"
      onClick={(e) => {
        e.stopPropagation();
        onView();
      }}
    >
      View
    </Button>
  );

  const sendButton = (
    <Button
      appearance="primary"
      onClick={(e) => {
        e.stopPropagation();
        onSendFile();
      }}
    >
      Send config
    </Button>
  );

  return (
    <Box
      pl={[2, 3, 3]}
      pr={[2, 3, 3]}
      pt={[2, 3, 3]}
      maxWidth={"1024px"}
      mx="auto"
      my={"auto"}
      mb={6}
    >
      <LoadingOverlay active={isSendingFile} spinner={<PropagateLoader />}>
        <Box
          py={[2, 3, 3]}
          px={[2, 4, 4]}
          width="100%"
          minHeight={512}
          style={{
            boxShadow:
              "rgba(34, 0, 51, 0.04) 0px 12px 18px 2px, rgba(7, 48, 114, 0.12) 0px 6px 22px 4px, rgba(14, 13, 26, 0.12) 0px 6px 10px -4px",
            borderRadius: "16px"
          }}
          bg={"rgba(10, 40, 40, .2)"}
        >
          <Box ml={3}>
            {projectInfo && (
              <ProjectInfoPanel
                projectInfo={projectInfo}
                slaveInfo={slaveInfo}
                updateProjectInfo={updateProjectInfo}
              />
            )}

            <Box pt={2} style={{}}>
              {isPDOMapFamilyAvailable ? (
                slaveInfo && (
                  <SlavesPanel
                    pdoMapFamilies={pdoMapFamilies}
                    slaveInfo={slaveInfo}
                    viewButton={viewButton}
                    //sendButton={sendButton}
                  />
                )
              ) : (
                <WarningFlag changeTab={changeTab} />
              )}
            </Box>

            {isPDOMapFamilyAvailable && (
              <Flex mt={3}>
                <Flex py={2} ml="auto">
                  <ModalTransition>
                    {isModalOpen && (
                      <Modal
                        //actions={actions}
                        actions={actions}
                        onClose={closeModalDialog}
                        heading="Result view"
                        width={1028}
                      >
                        <Flex flexDirection="column">
                          <Box>
                            <JsonView
                              src={result}
                              iconStyle={"circle"}
                              indentWidth={4}
                              name={false}
                              collapsed={false}
                              displayDataTypes={false}
                              displayObjectSize={false}
                              enableClipboard={false}
                            />
                          </Box>
                        </Flex>
                      </Modal>
                    )}
                  </ModalTransition>
                </Flex>
              </Flex>
            )}
          </Box>
        </Box>
      </LoadingOverlay>
    </Box>
  );
};

export default SlaveEditorTab;
