import React, { useCallback, useState, useEffect, useRef } from "react";
import { Box, Flex, Text } from "theme-ui";

import DragAndDropEsiFileScreen from "../DragAndDropEsiFileScreen";
import FileImportedScreen from "../FileImportedScreen";
import FileStoragedScreen from "../FileStoragedScreen";

import { storagedFilesService } from "../../services/storagedFilesService";
import { authenticationService } from "../../services/authenticationService";

import { processAcceptedFile } from "../../helpers/esiParsingTool";

import LoadingComponent from "../LoadingComponent";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import {
  notifyParsingFileError,
  notifyFileDuplicated,
  notifyFileSaved,
  notifyResponseError
} from "../toast-notifiers";
import _ from "lodash";

const FileUploadTab = (props) => {
  const [acceptedFileList, setAcceptedFileList] = useState({});
  const [storagedFileList, setStoragedFileList] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [mustSaveFile, setMustSaveFile] = useState(false);

  const { changeTab } = props;
  const { projectId } = useParams();

  const delayedSavePdoFiles = useRef(
    _.debounce((_projectId) => {
      return storagedFilesService
        .savePdoMapsFile(_projectId)
        .then((resData) => {
          notifyFileSaved(resData.fileNames);
        })
        .catch((err) => {});
    }, 1000)
  ).current;

  useEffect(() => {
    let subscription = null;
    // Component did mount, fetching pdoMapsFile;
    if (authenticationService.userAuhthenticated) {
      storagedFilesService
        .fetchPdoMapsFile(projectId)
        .then((pdoMapsFile) => {
          storagedFilesService.setPdoMapsFile(pdoMapsFile);
          subscription = storagedFilesService.subscribe(setStoragedFileList);
          setMustSaveFile(true);
        })
        .catch((err) => {
          //notifyResponseError(err.errors[0]);
          notifyResponseError("Some error here, sorry there is no detail.");
        });
    } else {
      subscription = storagedFilesService.subscribe(setStoragedFileList);
    }
    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [projectId]);

  useEffect(() => {
    if (mustSaveFile) {
      projectId && delayedSavePdoFiles(projectId);
    }
  }, [projectId, delayedSavePdoFiles, mustSaveFile]);

  const handleUploadAcceptedFiles = () => {
    setIsLoading(true);
    const promises = [];
    const fileNames = Object.keys(acceptedFileList);
    setTimeout(() => {
      fileNames.forEach((fileName) => {
        promises.push(
          new Promise(function (resolve, reject) {
            processAcceptedFile(acceptedFileList[fileName])
              .then((successFile) => {
                const fileName = Object.keys(successFile)[0];
                handleDeleteAcceptedFile(fileName);
                handleAddStoragedFileList(successFile);
                resolve();
              })
              .catch((failFile) => {
                const fileName = failFile.fileName;
                handleDeleteAcceptedFile(fileName);

                notifyParsingFileError(failFile.fileName, failFile.error);
                reject(failFile);
              });
          })
        );
      });
      Promise.all(promises)
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }, 500);
  };

  const handleDeleteAcceptedFile = (fileName) => {
    setAcceptedFileList((prevList) => {
      delete prevList[fileName];
      return { ...prevList };
    });
  };

  const handleDeleteStoragedFile = (fileName) => {
    storagedFilesService.deleteStoragedFileByName(fileName);
  };

  const handleAddStoragedFileList = (newStoragedFile) => {
    storagedFilesService.addNewStoragedFile(newStoragedFile);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      acceptedFiles.forEach((file, index) => {
        const fileName = file.name.trim();
        const size = file.size;

        const reader = new FileReader();
        // create file stupid id by name and size:
        const fileId = `${fileName}_${size}`;

        reader.readAsText(file, "UTF-8");
        reader.onload = (evt) => {
          const content = reader.result;
          // Stupid file id
          let fileIdDropList = Object.keys(acceptedFileList).map(
            (fileName) =>
              acceptedFileList[fileName].name +
              "_" +
              acceptedFileList[fileName].size
          );

          let fileIdStoragedList = Object.keys(storagedFileList).map(
            (fileName) =>
              storagedFileList[fileName].name +
              "_" +
              storagedFileList[fileName].size
          );
          const fileNotExistedDropList = fileIdDropList.indexOf(fileId) < 0;
          const fileNotExistedStoragedList =
            fileIdStoragedList.indexOf(fileId) < 0;

          if (fileNotExistedDropList && fileNotExistedStoragedList) {
            setAcceptedFileList((prevList) => {
              return {
                ...prevList,
                [fileName]: {
                  name: fileName,
                  size,
                  content
                }
              };
            });
          } else {
            notifyFileDuplicated(fileName);
          }
        };
      });
    },
    [acceptedFileList, storagedFileList]
  );

  const isFileImported = Object.keys(acceptedFileList).length > 0;
  return (
    <Box
      sx={{
        maxWidth: "1024px",
        px: 4,
        mx: ["auto"],
        mt: 2,
        mb: [6, 6, 6]
      }}
    >
      <Flex
        sx={{
          position: "relative"
        }}
      >
        {isLoading && <LoadingComponent animationKey="loading2" />}
        {mustSaveFile && (
          <LoadingComponent animationKey="loading2" message={"saving ..."} />
        )}
        <Flex
          sx={{
            py: [1, 3, 4],
            flexDirection: ["column", "column", "row"],
            px: 3,
            width: "100%",
            flexWrap: "wrap",
            boxShadow:
              "rgba(34, 0, 51, 0.04) 0px 12px 18px 2px, rgba(7, 48, 114, 0.12) 0px 6px 22px 4px, rgba(14, 13, 26, 0.12) 0px 6px 10px -4px",
            borderRadius: "16px"
          }}
        >
          <Flex
            sx={{
              flex: [1, 1, 1 / 2],
              minHeight: 320
            }}
          >
            {Object.keys(acceptedFileList).length > 0 ? (
              <FileImportedScreen
                onDrop={onDrop}
                acceptedFileList={acceptedFileList}
                handleDeleteFileByName={handleDeleteAcceptedFile}
                getRootProps={{}}
                getInputProps={{}}
                handleUpload={handleUploadAcceptedFiles}
                activeScreen={{}}
              />
            ) : (
              <DragAndDropEsiFileScreen onDrop={onDrop} />
            )}
          </Flex>
          <Flex
            sx={{
              py: [2],
              flex: [1, 1, 1 / 2],
              flexDirection: "column"
            }}
          >
            {Object.keys(storagedFileList).length > 0 ? (
              <FileStoragedScreen
                changeTab={changeTab}
                storagedFileList={storagedFileList}
                handleDeleteStoragedFile={handleDeleteStoragedFile}
              />
            ) : (
              <Greeting isFileImported={isFileImported} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const Greeting = (props) => {
  const { isFileImported } = props;
  return (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        px: 3,
        my: [2, "auto", "auto"]
      }}
    >
      <Text
        sx={{
          pb: 2,
          lineHeight: ["22px", "35px"],
          letterSpacing: "0.8px",
          fontSize: ["20px", "22px", "28px"],
          textAlign: "center",
          px: "auto",
          fontWeight: "500",
          textTransform: "uppercase"
        }}
      >
        {isFileImported
          ? "It look goods!"
          : "Easy to design Your EtherCAT application"}
      </Text>
      <Text
        sx={{
          mt: 1,

          textAlign: "center",
          fontStyle: "italic",
          fontSize: ["13px", "18px", "20px"],
          lineHeight: ["12px", "24px", "24px"]
        }}
      >
        {isFileImported ? (
          "Don't forget click on Upload and goto Family Editor Tab."
        ) : (
          <blockquote>
            No coding, just input file and feel free to design by provided UI.
          </blockquote>
        )}
      </Text>
    </Flex>
  );
};
export default FileUploadTab;
