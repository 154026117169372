export default {
  colors: {
    background: "white"
  },

  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: ".2em"
    }
  },

  links: {
    default: {
      textDecoration: "none"
    }
  },

  images: {
    avatar: {
      width: 48,
      height: 48,
      padding: "3px",
      border: "1px solid #1F2E37",
      borderRadius: 99999
    }
  },

  buttons: {
    primary: {
      outline: "none",
      //background: "rgba(230,0,35)",
      background: "#172B4D",
      ":hover, :active": {
        cursor: "pointer",
        //boxShadow: "3px 3px 8px 0px rgba(230,51,51,1)" ~ red
        boxShadow: "3px 3px 8px 0px rgba(23, 43, 77, 0.8)"
      }
    },
    header: {
      outline: "none",
      background: "rgba(3,168,124, 1)",
      fontSize: ["13px", "14px", "15px"],
      padding: "5px 15px 5px 15px",
      ":hover, :active": {
        cursor: "pointer",
        background: "rgba(3,168,124, 0.95)",
        boxShadow: "1px 1px 0px 1px rgba(0,0,0,0.75)"
      }
    }
  }
};
