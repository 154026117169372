import React from "react";
import AcceptedFileItem from "./AcceptedFileItem";

import { AnimatedList } from "react-animated-list";
const AnimatedFileList = props => {
  const { fileList, handleDeleteFileByName } = props;
  const fileNames = Object.keys(fileList);

  return (
    <AnimatedList animation={"fade"} key="familyAnimateList">
      {fileNames.map((fileName, index) => (
        <div key={fileName}>
          <AcceptedFileItem
            file={fileList[fileName]}
            name={fileName}
            handleDeleteFileByName={handleDeleteFileByName}
          />
        </div>
      ))}
    </AnimatedList>
  );
};

export default AnimatedFileList;
