import React, { useState, useEffect } from "react";

import { authenticationService } from "../../services/authenticationService";

import { usersProjectService } from "../../services/usersProjectService";
import { Grid, Avatar, Flex, Box, Text, Image } from "theme-ui";
import erobotLogo from "../../assets/images/erobot.png";
import AddIcon from "@atlaskit/icon/glyph/add";

//import avatarImg from "../../assets/images/beckhoff_logo.png";
//https://www.google.com/imgres?imgurl=http%3A%2F%2Fwww.stickerpacks.multiverso.de%2Fimg%2Ftiny-robot%2Flove_robot.gif&imgrefurl=http%3A%2F%2Fwww.stickerpacks.multiverso.de%2Ftiny-robot&docid=8vSvsHVhE0g6bM&tbnid=sT6yKZlYLOy3oM%3A&vet=1&w=408&h=408&itg=1&bih=944&biw=1897&ved=2ahUKEwjb_5vt-qHpAhVJeH0KHbAXCooQxiAoCHoECAEQMA&iact=c&ictx=1
//https://www.google.com/imgres?imgurl=http%3A%2F%2Fwww.stickerpacks.multiverso.de%2Fimg%2Ftiny-robot%2Flove_robot.gif&imgrefurl=http%3A%2F%2Fwww.stickerpacks.multiverso.de%2Ftiny-robot&docid=8vSvsHVhE0g6bM&tbnid=sT6yKZlYLOy3oM%3A&vet=1&w=408&h=408&itg=1&bih=944&biw=1897&ved=2ahUKEwjb_5vt-qHpAhVJeH0KHbAXCooQxiAoCHoECAEQMA&iact=c&ictx=1#h=408&imgdii=vkPjWOrsKh-c6M:&vet=1&w=408
import avatarImg from "../../assets/images/tiny-robot.gif";
import { useHistory } from "react-router-dom";
import { format } from "timeago.js";

import UseAnimations from "react-useanimations";
import { AnimatedList } from "react-animated-list";
import Dropdown, {
  DropdownItem,
  DropdownItemGroup
} from "@atlaskit/dropdown-menu";

import SignOutIcon from "@atlaskit/icon/glyph/sign-out";
import { notifyResponseError } from "../../components/toast-notifiers";

const DashboardLogo = ({ children, ...rest }) => {
  const history = useHistory();
  return (
    <Flex
      {...rest}
      sx={{
        alignItems: "center"
      }}
    >
      <Image
        onClick={() => {
          history.push("/");
        }}
        src={erobotLogo}
        width={70}
        sx={{
          ":hover": {
            cursor: "pointer"
          }
        }}
      />
      <Box
        mx={3}
        sx={{
          height: "70%",
          borderRight: "1px solid #45484A"
        }}
      />
      <Text as="h3">Dashboard</Text>
    </Flex>
  );
};

const getDropDownData = (history) => {
  return (
    <DropdownItemGroup title="USER">
      <DropdownItem
        onClick={() => {
          authenticationService.logout().then(() => {
            history.push("/");
          });
        }}
        elemBefore={<SignOutIcon size="small" />}
      >
        Sign out
      </DropdownItem>
    </DropdownItemGroup>
  );
};

const DashboardNavMenu = ({ children, ...rest }) => {
  const history = useHistory();
  const createNewProject = (e) => {
    e.preventDefault();
    usersProjectService
      .createNewProject()
      .then((resData) => {
        const projectId = resData.id;
        projectId && history && history.push(`/editor/${projectId}`);
      })
      .catch((err) => {
        notifyResponseError(err.errors[0]);
      });
  };

  return (
    <Flex
      {...rest}
      sx={{
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Flex
        sx={{
          mr: [3, 4, 4],
          p: 2,
          ":hover": {
            cursor: "pointer",
            borderRadius: "50%",
            color: "rgba(36,40,42, 0.8)"
          }
        }}
        onClick={createNewProject}
      >
        <AddIcon size="medium" primaryColor="inherit" />
      </Flex>
      <Dropdown
        position="bottom right"
        trigger={
          <Avatar
            src={avatarImg}
            sx={{
              ":hover": {
                bg: "rgba(251,186,70, 0.9)",
                cursor: "pointer",
                boxShadow: "2px 2px 4px 0px rgba(145,198,132,1)"
              }
            }}
          />
        }
      >
        {getDropDownData(history)}
      </Dropdown>
    </Flex>
  );
};

const FetchingNotification = (props) => (
  <Text
    color="#5e5d5e"
    as="h4"
    sx={{
      textAlign: "center"
    }}
  >
    Getting Saved Projects ...
  </Text>
);
const DashboardBodyTitle = ({ projectCount, ...rest }) => (
  <Flex {...rest} pt={[2, 3]}>
    <Text as="h4">Recent Projects {projectCount}</Text>
  </Flex>
);

const CreatedProjectPanel = (props) => {
  const { project, isActive, onClick, onDoubleClick, onRemoveProject } = props;
  const [isDeleting, setIsDeleting] = useState(project && project.isDeleting);

  return (
    <Flex
      onClick={onClick}
      onDoubleClick={() => {
        project && onDoubleClick && onDoubleClick(project.id);
      }}
      bg="#F2F2F2"
      my={2}
      sx={{
        flexDirection: "column",
        pointerEvents: isDeleting ? "none" : "default",
        opacity: isDeleting ? 0.3 : 1,
        width: "100%",
        borderRadius: "3px",
        boxShadow: isActive ? "5px 5px 9px -2px rgba(0,0,0,0.75)" : "none"
      }}
    >
      <div
        style={{
          position: "relative",
          height: "160px"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "0px"
          }}
        >
          <Flex
            m={1}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: "30px",
              width: "30px",

              color: "rgba(250, 20, 20, 0.8)",
              ":hover": {
                borderRadius: "3px",
                color: "rgba(250, 20, 20, 0.6)",
                background: !isDeleting ? "rgba(120, 120, 120, 0.2)" : "default"
              }
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation();
              if (!isDeleting) {
                setIsDeleting((prev) => !prev);
                onRemoveProject && onRemoveProject(project.id);
                project.isDeleting = true;
              }
            }}
          >
            <UseAnimations
              animationKey={isDeleting ? "loading2" : "trash"}
              key={!isDeleting ? "loadingAnimation" : "trashAnimation"}
              size={16}
              sx={{
                color: "red"
              }}
            />
          </Flex>
        </div>
      </div>
      <Box
        px={3}
        py={1}
        sx={{
          background: "#24282A",
          borderLeft: "solid 3px #61D9FA",
          borderBottomLeftRadius: "3px",
          borderBottomRightRadius: "3px"
        }}
      >
        <Text color="#F8FFFF">{project.name}</Text>
        <Text color="#757779">
          Edited {format(project.lastEditedDate)} in Simple Robot box
        </Text>
      </Box>
    </Flex>
  );
};

const DashboardProjectsContainer = (props) => {
  const { projects, onMovingToProjectEditor, onRemoveProject } = props;
  let projectIds = [];
  if (projects) {
    projectIds = Object.keys(projects);
  }
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);

  return (
    <Grid width={[512, 480, 280]}>
      <AnimatedList animation={"zoom"}>
        {projectIds.map((projectId, index) => {
          const isActve = index === selectedProjectIndex;
          const onItemClick = () => {
            setSelectedProjectIndex(index);
          };
          return (
            <CreatedProjectPanel
              key={`projectPanel-${projectId}`}
              project={projects[projectId]}
              isActive={isActve}
              onClick={onItemClick}
              onDoubleClick={(id) => {
                onMovingToProjectEditor(id);
              }}
              onRemoveProject={onRemoveProject}
            />
          );
        })}
      </AnimatedList>
    </Grid>
  );
};

const Spacer = () => <Box mx="auto" />;

const DashboardPage = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [projects, setProjects] = useState({});
  const [errors, setErrors] = useState({});
  const { history } = props;

  const hasErrors = Object.keys(errors).length > 0;
  useEffect(() => {
    setIsFetching(true);
    setTimeout(() => {
      usersProjectService
        .getProjectList()
        .then((_projects) => {
          setProjects(_projects);
          setIsFetching(false);
          setErrors({});
        })
        .catch((err) => {
          setErrors(err);
          setIsFetching(false);
        });
    }, 0);
  }, []);

  const handleRemoveProjectById = (id) => {
    usersProjectService
      .deleteProjectById(id)
      .then((deletedProject) => {
        if (delete projects[deletedProject.id]) {
          setProjects({ ...projects });
        }
      })
      .catch((err) => {
        setErrors(err);
      });
  };

  const movingToProjectEditor = (id) => {
    history && history.push(`/editor/${id}`);
  };

  return (
    <Box
      name="dashboardTemplate"
      sx={{
        pt: 3,
        minWidth: "512px"
      }}
    >
      <Flex name="dashboardTopNavBar">
        <DashboardLogo />
        <Spacer />
        <DashboardNavMenu
          pr={2}
          sx={{
            alignItems: "right"
          }}
        />
      </Flex>

      <Box
        name="dasboardBody"
        sx={{
          px: [2, 3, 3],
          height: "500px",
          width: "100%"
        }}
      >
        <DashboardBodyTitle projectCount={Object.keys(projects).length} />
        {isFetching ? (
          <FetchingNotification />
        ) : hasErrors ? (
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "50%"
            }}
          >
            <Text
              sx={{
                textAlign: "center",
                fontWeight: 400,
                fontSize: "2.6em",
                letterSpacing: 1.1
              }}
            >
              {errors.errors[0]} :(
            </Text>
            <Text
              pt={[1, 2, 2]}
              sx={{
                textAlign: "center",
                fontWeight: 400,
                fontSize: "0.9em",
                letterSpacing: 0.8
              }}
            >
              No worry, you're free for work now ^^
            </Text>
          </Flex>
        ) : (
          <Box pt={3}>
            <DashboardProjectsContainer
              projects={projects}
              onMovingToProjectEditor={movingToProjectEditor}
              onRemoveProject={handleRemoveProjectById}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DashboardPage;
