import { BehaviorSubject } from "rxjs";
import axios from "axios";
import {
  handleSaveProjectConfigFileResponse,
  handleFetchProjectConfigFileResponse,
  handleErrorResponse
} from "../helpers/handleApiServerResponse";

const initialValue = {
  ProjectInfo: [
    {
      PrjDir: "/home/pi",
      Name: "picat",
      Realtime: "xeno-3",
      Language: "c"
    },
    {
      MasterIndex: 0,
      SlaveNum: 0,
      DomainNum: 1
    },
    {
      DC: false,
      DCRefClock: 0,
      UseMasterTime: false
    }
  ],
  SlaveInfo: [{}]
};

const projectConfigStore = new BehaviorSubject(initialValue);

const clear = () => {
  projectConfigStore.next(initialValue);
};
const setProjectConfig = (projectConfig) => {
  projectConfigStore.next(projectConfig);
};

const setProjectInfo = (newInfo) => {
  const current = projectConfigStore.value;
  projectConfigStore.next({
    ...current,
    ProjectInfo: [...newInfo]
  });
};

const setSlaveInfo = (newInfo) => {
  const current = projectConfigStore.value;
  current.SlaveInfo = newInfo;
  projectConfigStore.next({ ...current });
};

const removeSlaveAt = async (_index) => {
  const current = projectConfigStore.value;

  if (_index > -1 && _index < current.SlaveInfo.length) {
    current.SlaveInfo = await current.SlaveInfo.filter(
      (_, index) => index !== _index
    );

    // update slave pos from deleted index;

    for (let i = _index; i < current.SlaveInfo.length; i++) {
      if (i === 0) {
        current.SlaveInfo[i].pos = 0;
      } else {
        const prevSlave = current.SlaveInfo[i - 1];

        current.SlaveInfo[i].pos = prevSlave.copies + prevSlave.pos;
      }
    }

    projectConfigStore.next({ ...current });
  }
};

const updateSlaveAt = (_index, newSlaveInfo) => {
  const current = projectConfigStore.value;
  if (_index > -1 && _index < current.SlaveInfo.length) {
    current.SlaveInfo[_index] = { ...newSlaveInfo };

    // if number of coppies changed:
    for (let i = 1; i < current.SlaveInfo.length; i++) {
      const prevSlave = current.SlaveInfo[i - 1];
      current.SlaveInfo[i].pos = prevSlave.copies + prevSlave.pos;
    }

    projectConfigStore.next({ ...current });
  }
};

const cloneSlaveAt = (_index) => {
  const current = projectConfigStore.value;

  if (_index > -1 && _index < current.SlaveInfo.length) {
    const lastSlave = current.SlaveInfo[current.SlaveInfo.length - 1];
    const clonedSlave = Object.assign({}, current.SlaveInfo[_index]);
    clonedSlave.pos = lastSlave.pos + lastSlave.copies;
    clonedSlave.copies = 1;
    current.SlaveInfo.push(clonedSlave);
    projectConfigStore.next({ ...current });
  }
};

const addNewSlaveInfo = (slaveInfo) => {
  const current = projectConfigStore.value;
  current.SlaveInfo.push(slaveInfo);
  projectConfigStore.next(current);
};

const saveProjectConfigFile = (projectId) => {
  const bodyFormData = new FormData();
  bodyFormData.append(
    "data",
    JSON.stringify(projectConfigStore.value, null, 2)
  );
  bodyFormData.append("fileNames", Object.keys(projectConfigStore.value));
  if (!projectId) {
    return Promise.reject({
      errors: ["There is no project id in request"]
    });
  }

  return axios(
    `http://localhost:5001/api/users/projects/${projectId}/project-config-file`,
    //`http://localhost:5001/api/users/projects/ouMczsSGW/project-pdos-file`,
    {
      method: "POST",
      data: bodyFormData,
      withCredentials: "same-origin",

      headers: {
        "content-type": "multipart/form-data"
      }
    }
  )
    .then(
      (res) => handleSaveProjectConfigFileResponse(res),
      (error) => handleErrorResponse(error)
    )
    .then((responseData) => {
      return Promise.resolve(responseData);
    });
};

const fetchProjectConfigFile = (projectId) => {
  if (!projectId) {
    return Promise.reject({
      errors: ["There is no project id in request"]
    });
  }
  return axios(
    `http://localhost:5001/api/users/projects/${projectId}/project-config-file`,
    {
      method: "GET",
      withCredentials: "same-origin"
    }
  )
    .then(
      (res) => handleFetchProjectConfigFileResponse(res),
      (error) => handleErrorResponse(error)
    )
    .then((pdoMapsFile) => {
      return Promise.resolve(pdoMapsFile);
    });
};

export const projectConfigService = {
  get currentProjectConfigValue() {
    return projectConfigStore.value;
  },
  clear,
  setProjectConfig,
  setProjectInfo,
  setSlaveInfo,
  addNewSlaveInfo,
  removeSlaveAt,
  updateSlaveAt,
  cloneSlaveAt,
  saveProjectConfigFile,
  fetchProjectConfigFile,

  subscribe: (setState) => {
    return projectConfigStore.subscribe(setState);
  }
};
