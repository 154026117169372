import React from "react";
import { Box, Flex, Text } from "theme-ui";

const FamilyInfoDisplay = props => {
  const { selectedPDOFamily } = props;
  return (
    <Flex
      sx={{
        pb: [3, 3, 0],
        mr: [2],
        minHeight: "60px",
        maxHeight: "120px",
        my: "auto"
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          width: "auto",

          mr: 2,
          px: 3,
          py: 1,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          background: "rgba(197, 161, 85, 0.4)",
          //border: "solid 1.5px #16294C"
          boxShadow: "1px 1px 4px 0px rgba(179,158,120, .8)"
        }}
      >
        <Text
          as="h4"
          sx={{
            fontSize: ["14px", "15px", "15px"],
            textAlign: "center",
            color: "inherit"
          }}
        >
          {"Profile"}
        </Text>
        <Box
          sx={{
            my: 1,
            width: "100%",
            border: "0.8px solid #0D594C"
          }}
        />
        <Text
          as="h5"
          sx={{
            fontSize: ["14px", "16px", "16px"],
            textAlign: "center",
            color: "inherit"
          }}
        >
          {selectedPDOFamily.profileNo}
        </Text>
      </Flex>

      <Flex
        sx={{
          flexDirection: "column",
          ml: 2,
          mr: 2,
          px: 3,
          width: 120,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          background: "rgba(203, 228, 38, 0.4)",
          boxShadow: "1px 1px 4px 0px rgba(176,146,86, 0.6)"
        }}
      >
        <Text
          as="h4"
          sx={{
            fontSize: ["14px", "15px", "15px"],
            textAlign: "center",
            color: "inherit"
          }}
        >
          {"Vid"}
        </Text>
        <Box
          sx={{
            my: 1,
            width: "100%",
            border: "0.8px solid #0D594C"
          }}
        />
        <Text
          as="h5"
          sx={{
            fontSize: ["14px", "16px", "16px"],
            textAlign: "center",
            color: "inherit"
          }}
        >
          {selectedPDOFamily.vid}
        </Text>
      </Flex>

      <Flex
        sx={{
          flexDirection: "column",
          ml: 2,
          mr: 2,
          px: 3,
          width: 120,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          background: "rgba(98, 170, 45, 0.4)",
          boxShadow: "1px 1px 4px 0px rgba(133,168,104,0.8)"
        }}
      >
        <Text
          as="h4"
          fontSize={["14px", "15px", "15px"]}
          textAlign="center"
          color="inherit"
        >
          {"Pro code"}
        </Text>
        <Box
          sx={{
            my: 1,
            width: "100%",
            border: "0.8px solid #0D594C"
          }}
        />
        <Text as="h5" textAlign="center" color="inherit">
          {selectedPDOFamily.proCode}
        </Text>
      </Flex>
    </Flex>
  );
};
export default FamilyInfoDisplay;
