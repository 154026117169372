import React from "react";
import { Flex, Box } from "theme-ui";
import InlineNavBar from "./InlineNavBar";

export default props => {
  return (
    <Flex
      sx={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1px solid #dddddd"
      }}
    >
      <Box
        sx={{
          ml: "auto"
        }}
      />
      <Box>
        <InlineNavBar {...props} />
      </Box>
    </Flex>
  );
};
