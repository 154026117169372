import { BehaviorSubject } from "rxjs";
import {
  handleSavePdoMapsFileResponse,
  handleFetchPdoMapsFileResponse,
  handleErrorResponse
} from "../helpers/handleApiServerResponse";
import axios from "axios";
const initialValue = {};
const storagedFilesStore = new BehaviorSubject(initialValue);

const clear = () => {
  storagedFilesStore.next(initialValue);
};
function addNewStoragedFile(storagedFile) {
  storagedFilesStore.next({
    ...storagedFilesStore.value,
    ...storagedFile
  });
}

function setPdoMapsFile(pdoMapsContent) {
  storagedFilesStore.next(pdoMapsContent);
}

function deleteStoragedFileByName(fileName) {
  let newValue = storagedFilesStore.value;
  newValue && delete newValue[fileName];
  storagedFilesStore.next({
    ...newValue
  });
}

const savePdoMapsFile = projectId => {
  const bodyFormData = new FormData();
  bodyFormData.append(
    "data",
    JSON.stringify(storagedFilesStore.value, null, 2)
  );
  bodyFormData.append("fileNames", Object.keys(storagedFilesStore.value));
  if (!projectId) {
    return Promise.reject({
      errors: ["There is no project id in request"]
    });
  }
  return axios(
    `http://localhost:5001/api/users/projects/${projectId}/project-pdos-file`,
    //`http://localhost:5001/api/users/projects/ouMczsSGW/project-pdos-file`,
    {
      method: "POST",
      data: bodyFormData,
      withCredentials: "same-origin",

      headers: {
        "content-type": "multipart/form-data"
      }
    }
  )
    .then(
      res => handleSavePdoMapsFileResponse(res),
      error => handleErrorResponse(error)
    )
    .then(responseData => {
      return Promise.resolve(responseData);
    });
};

const fetchPdoMapsFile = projectId => {
  if (!projectId) {
    return Promise.reject({
      errors: ["There is no project id in request"]
    });
  }

  return axios(
    `http://localhost:5001/api/users/projects/${projectId}/project-pdos-file`,
    {
      method: "GET",
      withCredentials: "same-origin"
    }
  )
    .then(
      res => handleFetchPdoMapsFileResponse(res),
      error => handleErrorResponse(error)
    )
    .then(pdoMapsFile => {
      //  currentUserSubject.next(user);
      return Promise.resolve(pdoMapsFile);
    });
};

export const storagedFilesService = {
  currentStoragedFiles: storagedFilesStore.asObservable(),
  get currentStoragedFilesValue() {
    return storagedFilesStore.value;
  },
  get pdoSelectOptions() {
    const selectOptions = {
      rxPDOOptions: [],
      txPDOOptions: []
    };
    const storagedFiles = storagedFilesStore.value;
    const fileNames = Object.keys(storagedFiles);
    fileNames &&
      fileNames.forEach(name => {
        selectOptions.rxPDOOptions.push(...storagedFiles[name]["rxPDOOptions"]);
        selectOptions.txPDOOptions.push(...storagedFiles[name]["txPDOOptions"]);
      });
    return selectOptions;
  },
  subscribe: setState => {
    return storagedFilesStore.subscribe(setState);
  },
  clear,
  addNewStoragedFile,
  setPdoMapsFile,
  deleteStoragedFileByName,
  savePdoMapsFile,
  fetchPdoMapsFile
};
