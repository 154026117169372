import React from "react";
import PDOMapFamilyItem from "./PDOMapFamilyItem";

import { AnimatedList } from "react-animated-list";
import { Flex } from "theme-ui";
const PDOMapFamilyList = props => {
  const {
    pdoMapFamilies,
    selectedPDOFamilyId,
    setActiveIndex,
    onPDOFamilySelected,
    onRemovePDOFamily
  } = props;

  return (
    <Flex
      sx={{
        flexWrap: "wrap"
      }}
    >
      <AnimatedList key="animateFamilylist">
        {pdoMapFamilies.map((pdoFamily, index) => {
          const familyId = pdoFamily.id;
          const isActive = familyId === selectedPDOFamilyId;
          return (
            <Flex
              key={`family-${index}`}
              sx={{
                pb: 2
              }}
            >
              <PDOMapFamilyItem
                itemIndex={index}
                key={`family-${familyId}`}
                isActive={isActive}
                setActiveIndex={setActiveIndex}
                onPDOFamilySelected={onPDOFamilySelected}
                onRemovePDOFamily={onRemovePDOFamily}
                familyId={familyId}
              />
            </Flex>
          );
        })}
      </AnimatedList>
    </Flex>
  );
};

export default PDOMapFamilyList;
