import * as Yup from "yup";
import Schema from "validate";

export const userSigninSchema = Yup.object().shape({
  username: Yup.string()
    .min(3)
    .max(30)
    .required(),
  password: Yup.string()
    .min(3)
    .max(30)
    .required()
});

export const userSignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(3)
    .max(30)
    .notRequired(),
  username: Yup.string()
    .min(3)
    .max(30)
    .required(),
  password: Yup.string()
    .min(3)
    .max(30)
    .required(),
  hint: Yup.string()
    .min(1)
    .max(30)
    .required()
});

export const errorResponseSchema = new Schema([{ type: String }]);

export const userResponseSchema = new Schema({
  userAuthToken: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: false
  },
  username: {
    type: String,
    required: false
  }
});
