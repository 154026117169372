//https://www.protopie.io/
import React from "react";

import HomePage from "./pages/HomePage";

import DashboardPage from "./pages/dashboard/DashboardPage";

import EditorPage from "./pages/EditorPage";

//import PrivateRoute from "./components/routes/PrivateRoute";
import AuthRedirectRoute from "./components/routes/AuthRedirectRoute";
import PrivateRoute from "./components/routes/PrivateRoute";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import theme from "./themes/default";

export default function App() {
  return (
    //<DevPage />
    /*
    <ThemeProvider theme={theme}>
      <Switch>
        <AuthRedirectRoute path="/" exact component={HomePage} />

        <Route path="/editor" component={EditorPage} />
        <AuthRedirectRoute path="/signup" component={SignupPage} />
        <AuthRedirectRoute path="/signin" component={SigninPage} />
        <Route path="/dashboard" component={DashboardPage} />
      </Switch>
    </ThemeProvider>*/

    <ThemeProvider theme={theme}>
      <Switch>
        <AuthRedirectRoute path="/" exact component={HomePage} />
        <Route path="/editor/:projectId" component={EditorPage} />
        <PrivateRoute path="/dashboard" component={DashboardPage} />
      </Switch>
    </ThemeProvider>
  );
}
