import React, { useState } from "react";
import { Flex, Text, Box } from "rebass";
import { Input, Select } from "@rebass/forms";

import { PanelStateless } from "@atlaskit/panel";
import Toggle from "@atlaskit/toggle";

import styled from "styled-components";

const Label = styled(Text)`
  color: #929292;
  font-weight: 600;
  letter-spacing: 1.5px;
`;

const Inputfield = styled(Input)`
  padding: "14px";
  border: none;
  background: #f0f0f0;
  border-radius: 5px;
`;

const ProjectInfoPanel = props => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { projectInfo, slaveInfo, updateProjectInfo } = props;

  const updateInfo = (id, e) => {
    const type = e.target.type;
    if (type === "number") {
      projectInfo[id][e.target.name] = parseInt(e.target.value, 10);
    } else {
      projectInfo[id][e.target.name] = e.target.value;
    }
    updateProjectInfo && updateProjectInfo([...projectInfo]);
  };

  const handleToggleChange = e => {
    projectInfo[2][e.target.name] = e.target.checked;
    updateProjectInfo && updateProjectInfo([...projectInfo]);
  };

  return (
    <PanelStateless
      isExpanded={isExpanded}
      onChange={state => setIsExpanded(state)}
      header={
        <Flex width="auto">
          <Text
            pb={1}
            as="h3"
            sx={{
              borderBottom: isExpanded ? "solid 2px red" : "none"
            }}
          >
            PROJECT INFO
          </Text>
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <Text as="h4">Configuration</Text>
        <Flex flexDirection="column" pt={2}>
          <Flex px={[2, 3, 3]}>
            <Box
              mr={3}
              width={210}
              sx={{
                //border: 'solid 1px ',
                borderRadius: "8px",
                background: "#16294C"
              }}
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Text color="white" fontSize={["24px", "26px", "26px"]}>
                  {slaveInfo ? slaveInfo.length : 0}
                </Text>
                <Box
                  width={"80%"}
                  sx={{
                    border: "1px solid #00875A"
                  }}
                />
                <Text
                  color="white"
                  fontWeight="500"
                  letterSpacing="1.1px"
                  fontSize={["12px", "14px", "14px"]}
                >
                  slaves
                </Text>
              </Flex>
            </Box>
            <Box width={1} pr={2}>
              <Label htmlFor="PrjDir" fontSize={["9.5px", "14px", "14px"]}>
                Project dir
              </Label>
              <Inputfield
                mt={1}
                py={[1, 2, 2]}
                name="PrjDir"
                defaultValue={projectInfo[0]["PrjDir"]}
                placeholder="/home/pi"
                onChange={e => {
                  updateInfo(0, e);
                }}
              />
            </Box>
            <Box width={1} pr={2}>
              <Label htmlFor="Name" fontSize={["9.5px", "14px", "14px"]}>
                App name
              </Label>
              <Inputfield
                mt={1}
                py={[1, 2, 2]}
                name="Name"
                placeholder="name"
                defaultValue={projectInfo[0]["Name"]}
                onChange={e => {
                  updateInfo(0, e);
                }}
              />
            </Box>
            <Box width={1} pr={2}>
              <Label htmlFor="Name" fontSize={["9.5px", "14px", "14px"]}>
                RT app
              </Label>
              <Select
                py={[1, 2, 2]}
                minWidth={["auto", "auto", "auto"]}
                sx={{
                  borderRadius: "5px"
                }}
                mt={1}
                name="Realtime"
                placeholder="none"
                value={projectInfo[0]["Realtime"]}
                onChange={e => {
                  updateInfo(0, e);
                }}
              >
                <option key="opt-none">none</option>
                <option key="opt-preempt">preempt-rt</option>
                <option key="opt-xeno2">xeno-2</option>
                <option key="opt-xeno3">xeno-3</option>
                <option key="opt-xeno4">xeno-4</option>
                <option key="opt-mercury">xeno-mercury</option>
              </Select>
            </Box>

            <Box width={1} pr={2}>
              <Label htmlFor="Name" fontSize={["9.5px", "14px", "14px"]}>
                Language
              </Label>
              <Select
                py={[1, 2, 2]}
                minWidth={["auto", "auto", "auto"]}
                sx={{
                  borderRadius: "5px"
                }}
                mt={1}
                name="Language"
                placeholder="none"
                value={projectInfo[0]["Language"]}
                onChange={e => {
                  updateInfo(0, e);
                }}
              >
                <option key="c++">c++</option>
                <option key="c">c</option>
              </Select>
            </Box>
          </Flex>

          <Flex pt={[3]} px={2}>
            <Box width={1} pr={2}>
              <Label htmlFor="MasterIndex" fontSize={["11px", "14px", "14px"]}>
                Master index
              </Label>
              <Inputfield
                mt={1}
                py={[1, 2, 2]}
                name="MasterIndex"
                placeholder={0}
                onChange={e => {
                  updateInfo(1, e);
                }}
                value={projectInfo[1]["MasterIndex"]}
                type="number"
              />
            </Box>
            <Box width={1} pr={2}>
              <Label htmlFor="DCRefClock" fontSize={["11px", "14px", "14px"]}>
                DC ref clock
              </Label>
              <Inputfield
                mt={1}
                py={[1, 2, 2]}
                name="DCRefClock"
                placeholder={0}
                onChange={e => {
                  updateInfo(2, e);
                }}
                type="number"
                value={projectInfo[2]["DCRefClock"]}
              />
            </Box>
            <Box width={1}>
              <Label htmlFor="DomainNum" fontSize={["11px", "14px", "14px"]}>
                Domain num
              </Label>
              <Inputfield
                py={[1, 2, 2]}
                type="number"
                min={1}
                max={2}
                name="DomainNum"
                mt={1}
                placeholder={0}
                onChange={e => {
                  updateInfo(1, e);
                }}
                value={projectInfo[1]["DomainNum"]}
              />
            </Box>
          </Flex>
        </Flex>

        <Text pt={3} as="h4">
          Enables
        </Text>
        <Flex pt={3} px={3} flexDirection={["column", "row", "row"]}>
          <Flex
            width={1}
            mb={[2, 0, 0]}
            pl={3}
            pr={2}
            alignItems="center"
            justifyContent="space-between"
            mx={1}
            bg="#f2f3f6"
            sx={{
              borderRadius: "50px"
            }}
          >
            <Label fontSize={[12, 14, 14]}>DC</Label>

            <Toggle
              name="DC"
              size="large"
              isDefaultChecked={projectInfo[2].DC}
              onChange={handleToggleChange}
            />
          </Flex>
          <Flex
            width={1}
            pl={3}
            pr={2}
            alignItems="center"
            justifyContent="space-between"
            mx={1}
            bg="#f2f3f6"
            sx={{
              borderRadius: "50px"
            }}
          >
            <Label fontSize={[12, 14, 14]}>Use master time</Label>
            <Toggle
              name="UseMasterTime"
              size="large"
              isDefaultChecked={projectInfo[2].UseMasterTime}
              onChange={handleToggleChange}
            />
          </Flex>
        </Flex>
      </Flex>
    </PanelStateless>
  );
};

export default ProjectInfoPanel;
