import React, { useState } from "react";
import { authenticationService } from "../services/authenticationService";
import { InputErrorMessages } from "../components/InputErrorMessages";

import { userSigninSchema } from "../validator-schemas";

import { Flex, Button, Text, Box, Link, Container } from "theme-ui";

import { Label, Input, Checkbox } from "@rebass/forms";

const SigninForm = (props) => {
  const [userInfo, setUserInfo] = useState({
    username: "",
    password: ""
    //remembered: false
  });

  const [errors, setErrors] = useState([]);
  const { setIsLoading } = props;
  const handleInputChange = function (e) {
    const evtName = e.target.name;
    const eValue = e.target.value;
    setUserInfo((input) => ({
      ...input,
      [evtName]: eValue
    }));
  };

  if (authenticationService.currentUserValue) {
    //props.history.push("/dashboard");
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const isValid = userSigninSchema.validateSync(userInfo, {
        abortEarly: false
      });
      if (isValid) {
        authenticationService.signin(userInfo).then(
          (user) => {
            setIsLoading(false);
            //const { from } = props.location.state || {
            //   from: { pathname: "/dashboard" }
            // };
            props.history.push("/dashboard");
          },
          (err) => {
            // Fail -> Set errors with loading state
            setIsLoading(false);
            setErrors(err.errors);
          }
        );
      }
    } catch (ex) {
      setIsLoading(false);
      setErrors(ex.errors);
    }
  };
  return (
    <Flex>
      <Flex
        mx="auto"
        sx={{
          width: "100%"
        }}
      >
        <Container variant="login-input" py={4} sx={{}}>
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text as="h2" pb={3}>
              Welcome Back
            </Text>
            <Box>
              <InputErrorMessages errors={errors} />
            </Box>
            <Box
              as="form"
              px={3}
              onSubmit={onSubmit}
              sx={{
                width: "100%"
              }}
            >
              <Label htmlFor="username">Username</Label>
              <Input name="username" mb={3} onChange={handleInputChange} />
              <Label htmlFor="password">Password</Label>
              <Input
                type="password"
                name="password"
                mb={3}
                onChange={handleInputChange}
              />
              <Box>
                <Label mb={3}>
                  <Checkbox />
                  Remember me
                </Label>
              </Box>
              <Box mt={3}>
                <Link ml={1} sx={{}}>
                  Don't remember password?
                </Link>
              </Box>
              <Button
                mt={4}
                sx={{
                  width: "100%"
                }}
              >
                Connect
              </Button>
            </Box>
          </Flex>
        </Container>
      </Flex>
    </Flex>
  );
};

export default SigninForm;
