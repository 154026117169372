import { BehaviorSubject } from "rxjs";
import {
  handleSavePdoFamiliesFileResponse,
  handleFetchPdoFamiliesFileResponse,
  //handleFetchPdoFamiliesFileResponse,
  handleErrorResponse
} from "../helpers/handleApiServerResponse";

import axios from "axios";
import shortid from "shortid";

const initialValue = [];
const pdoMapFamiliesStore = new BehaviorSubject(initialValue);

const addNewPDOMapFamily = () => {
  return new Promise((resolve, reject) => {
    const newFamily = {
      id: shortid.generate(),
      profileNo: "",
      vid: "", // vendor id
      deviceName: "", // device name
      proCode: "", //productCode
      pdoGroup: {
        RxPDO: "",
        TxPDO: ""
      },
      selectedOption: {
        RxPDO: null,
        TxPDO: null
      },

      RxPDO: [],
      TxPDO: []
    };
    pdoMapFamiliesStore.next([...pdoMapFamiliesStore.value, newFamily]);
    resolve(newFamily);
  });
};

const clear = () => {
  pdoMapFamiliesStore.next(initialValue);
};

const setPdoFamilies = (families) => {
  return new Promise((resolve, reject) => {
    pdoMapFamiliesStore.next([...families]);
    resolve(families);
  });
};

const removePDOMapFamily = (familyId) => {
  return new Promise((resolve, reject) => {
    const newPdoMapFamilies = pdoMapFamiliesStore.value.filter(
      (family) => family.id !== familyId
    );
    pdoMapFamiliesStore.next(newPdoMapFamilies);
    resolve(newPdoMapFamilies);
  });
};

const updateFamilyPDOEntry = (pdoFamily, pdoType, pdoIndex, newEntries) => {
  const pdoFamilyIndex = pdoFamily.id;
  pdoFamily[pdoType][pdoIndex]["pdo"]["entries"] = newEntries;

  pdoFamilyIndex != null &&
    updatePDOMapFamilies({ ...pdoFamily }, pdoFamilyIndex);
};

const updatePDOMapFamilies = (newFamily, selectedPDOFamilyId) => {
  const selectedFamilyPosition = pdoMapFamiliesStore.value.findIndex(
    (newFamily) => newFamily.id === selectedPDOFamilyId
  );

  pdoMapFamiliesStore.value[selectedFamilyPosition] = {
    ...pdoMapFamiliesStore.value[selectedFamilyPosition],
    ...newFamily
  };

  pdoMapFamiliesStore.next([...pdoMapFamiliesStore.value]);
};

const emptyPDOMapFamily = () => {
  pdoMapFamiliesStore.next([]);
};

const savePDOMapFamilyFile = (projectId) => {
  const bodyFormData = new FormData();
  bodyFormData.append(
    "data",
    JSON.stringify(pdoMapFamiliesStore.value, null, 2)
  );
  bodyFormData.append("fileNames", Object.keys(pdoMapFamiliesStore.value));
  if (!projectId) {
    return Promise.reject({
      errors: ["There is no project id in request"]
    });
  }
  return axios(
    `http://localhost:5001/api/users/projects/${projectId}/project-pdo-families-file`,
    //`http://localhost:5001/api/users/projects/ouMczsSGW/project-pdos-file`,
    {
      method: "POST",
      data: bodyFormData,
      withCredentials: "same-origin",

      headers: {
        "content-type": "multipart/form-data"
      }
    }
  )
    .then(
      (res) => handleSavePdoFamiliesFileResponse(res),
      (error) => handleErrorResponse(error)
    )
    .then((responseData) => {
      return Promise.resolve(responseData);
    });
};

const fetchPDOMapFamilyFile = (projectId) => {
  if (!projectId) {
    return Promise.reject({
      errors: ["There is no project id in request"]
    });
  }
  return axios(
    `http://localhost:5001/api/users/projects/${projectId}/project-pdo-families-file`,
    {
      method: "GET",
      withCredentials: "same-origin"
    }
  )
    .then(
      (res) => handleFetchPdoFamiliesFileResponse(res),
      (error) => handleErrorResponse(error)
    )
    .then((pdoMapsFile) => {
      //  currentUserSubject.next(user);
      return Promise.resolve(pdoMapsFile);
    });
};
export const pdoMapFamiliesService = {
  currentPdoMapFamilies: pdoMapFamiliesStore.asObservable(),
  get currentPdoMapFamiliesValue() {
    return pdoMapFamiliesStore.value;
  },

  subscribe: (setState) => {
    return pdoMapFamiliesStore.subscribe(setState);
  },
  unsubscribe: (setState) => {
    pdoMapFamiliesStore.unsubscribe(setState);
  },
  updatePDOMapFamilies,
  setPdoFamilies,
  removePDOMapFamily,
  addNewPDOMapFamily,
  emptyPDOMapFamily,
  updateFamilyPDOEntry,
  savePDOMapFamilyFile,
  fetchPDOMapFamilyFile,
  clear
};
