import React from "react";
import Select, { components } from "react-select";
import Button from "@atlaskit/button";
import { Flex, Box } from "theme-ui";
import Tooltip from "@atlaskit/tooltip";

const PDOSelect = (props) => {
  const { pdoType, pdoSelectOption, family, updateFamily } = props;

  const selectedPDOGroup = {
    ...family.pdoGroup,
    [pdoType]: family.pdoGroup[pdoType]
  };

  const excludedList = [];
  if (family[pdoType] && family[pdoType].length > 0) {
    family[pdoType].forEach((pdoItem) => {
      pdoItem["pdo"]["exclude"].forEach((index) => {
        excludedList.push(index);
      });
    });
  }

  const callActions = {
    "select-option": (deliver, value) => {
      const option = deliver.option;
      const { profileNo, pdoGroup, deviceName, vendorId, productCode } = option;
      if (!family["selectedOption"][pdoType]) {
        updateFamily({
          ...family,
          profileNo,
          vid: vendorId,
          proCode: productCode,
          deviceName,

          pdoGroup: {
            ...family["pdoGroup"],
            RxPDO: pdoGroup,
            TxPDO: pdoGroup
          },
          selectedOption: {
            ...family["selectedOption"],
            [pdoType]: option["value"]
          },
          [pdoType]: [...value] // Rx or Tx
        });
      } else {
        updateFamily({
          ...family,
          selectedOption: {
            ...family["selectedOption"],
            [pdoType]: option["value"]
          },
          [pdoType]: [...family[pdoType], option] // Rx or Tx
        });
      }
    },

    "deselect-option": (deliver, value) => {},

    clear: (deliver, value) => {
      if (family["RxPDO"].length === 0 || family["TxPDO"].length === 0) {
        updateFamily({
          ...family,
          profileNo: "",
          pdoGroup: {
            ...family.pdoGroup,
            RxPDO: null,
            TxPDO: null
          },
          vid: "", // vendor id
          deviceName: "", // device name
          proCode: "", //productCode
          [pdoType]: [],
          selectedOption: {
            ...family["selectedOption"],
            [pdoType]: null
          }
        });
      } else {
        updateFamily({
          ...family,
          pdoGroup: {
            ...family.pdoGroup,
            [pdoType]: ""
          },
          [pdoType]: [],
          selectedOption: {
            ...family["selectedOption"],
            [pdoType]: null
          }
        });
      }
    },

    "remove-value": (deliver, value) => {
      // There is no select
      const removedValue = deliver.removedValue;
      const removedValueIndex = family[pdoType].indexOf(removedValue);
      const selectedOptionIndex = family[pdoType]
        .map((pdoSelect) => pdoSelect.value)
        .indexOf(family["selectedOption"][pdoType]);

      if (value === null) {
        let anothertType = "TxPDO";
        if (pdoType === anothertType) {
          anothertType = "RxPDO";
        }

        if (family[pdoType].length === 1 && family[anothertType].length === 0) {
          // Clear all

          updateFamily({
            ...family,
            profileNo: "",
            vid: "",
            proCode: "",
            pdoGroup: {
              ...family.pdoGroup,
              [pdoType]: null,
              [anothertType]: null
            },
            selectedOption: {
              ...family["selectedOption"],
              [pdoType]: null,
              [anothertType]: null
            },
            [pdoType]: [],
            [anothertType]: []
          });
        } else {
          updateFamily({
            ...family,
            pdoGroup: {
              ...family.pdoGroup,
              [pdoType]: null
            },
            selectedOption: {
              ...family["selectedOption"],
              [pdoType]: null
            },
            [pdoType]: []
          });
        }
      } else {
        if (removedValueIndex !== selectedOptionIndex) {
          updateFamily({
            ...family,
            [pdoType]: [...value]
          });
        } else {
          updateFamily({
            ...family,
            [pdoType]: value,
            selectedOption: {
              ...family["selectedOption"],
              [pdoType]: family[pdoType][0]["value"]
            }
          });
        }
      }
    }
  };

  const onChange = (value, deliver) => {
    if (deliver.action !== "pop-value")
      callActions[deliver.action](deliver, value);
  };

  return (
    <Flex
      sx={{
        flex: 1,
        alignItems: "center",
        px: 3
      }}
    >
      <Box
        sx={{
          flex: 1
        }}
      >
        <Select
          isClearable={false}
          closeMenuOnSelect={true}
          openMenuOnClick={false}
          //isOptionSelected={true}
          options={pdoSelectOption}
          //value={family.pdoOptions}
          value={family[pdoType]}
          onChange={onChange}
          /*
          isOptionDisabled={option => {
            const isExcluded =
              excludedList.indexOf(option["pdo"]["index"]) > -1;
            if (isExcluded) {
              return true;
            }

            if (!selectedPDOGroup["RxPDO"] && !selectedPDOGroup["TxPDO"]) {
              return false;
            }

            return (
              option.pdoGroup !== selectedPDOGroup["RxPDO"] &&
              option.pdoGroup !== selectedPDOGroup["TxPDO"]
            );
          }}*/

          styles={{
            option: (provided, state) => {
              const isExcluded =
                excludedList.indexOf(state.data["pdo"]["index"]) > -1;

              isExcluded && (provided["color"] = "rgba(225, 20, 20, .4)");
              state.data.sm > 0 && (provided["background"] = "#DEE0E3");
              return {
                ...provided
              };
            },
            groupHeading: (provided, state) => {
              provided["color"] = "rgb(34,167,115)";
              provided["fontWeight"] = "bold";
              provided["fontSize"] = "12px";
              provided["fontStyle"] = "italic";
              const matches = state.children.match(/\[(.*?)\]/);
              if (matches) {
                const groupdId = matches[1];

                if (!selectedPDOGroup["RxPDO"] && !selectedPDOGroup["TxPDO"]) {
                  return { ...provided };
                }

                if (
                  groupdId !== selectedPDOGroup["RxPDO"] &&
                  groupdId !== selectedPDOGroup["TxPDO"]
                ) {
                  provided["color"] = "rgba(21,21,21, 0.4)";
                  provided["fontWeight"] = "bold";
                  provided["fontSize"] = "12px";
                  provided["fontStyle"] = "italic";
                  return { ...provided };
                }
              }
              return {
                ...provided
              };
            }
          }}
          components={{
            Option: (props) => {
              const isExcluded =
                excludedList.indexOf(props.data["pdo"]["index"]) > -1;
              let optionLabel =
                props.data.sm > 0
                  ? `${props.children} @sm`
                  : `${props.children}`;
              let entries = null;
              if (props.data && props.data.pdo && props.data.pdo.entries) {
                entries = props.data.pdo.entries;
              }

              const rows =
                entries &&
                entries.map((entry) => {
                  return (
                    <tr>
                      <td>{entry.index}</td>
                      <td>{entry.name}</td>
                    </tr>
                  );
                });

              if (isExcluded) return null;
              if (!selectedPDOGroup["RxPDO"] && !selectedPDOGroup["TxPDO"]) {
                return (
                  <Tooltip
                    position={"mouse"}
                    content={
                      <table>
                        <tbody>
                          <tr>
                            <th>Entries of</th>
                            <th>{props.data.label}</th>
                          </tr>
                        </tbody>
                        {rows}
                      </table>
                    }
                    truncateText
                  >
                    <components.Option {...props}>
                      {optionLabel}
                    </components.Option>
                  </Tooltip>
                );
              }

              if (
                props.data.pdoGroup !== selectedPDOGroup["RxPDO"] &&
                props.data.pdoGroup !== selectedPDOGroup["TxPDO"]
              ) {
                return null;
              }
              return (
                <Tooltip
                  position={"mouse"}
                  content={
                    <table>
                      <tbody>
                        <tr>
                          <th>Entries of</th>
                          <th>{props.data.label}</th>
                        </tr>
                      </tbody>
                      {rows}
                    </table>
                  }
                  truncateText
                >
                  <components.Option {...props}>
                    {optionLabel}
                  </components.Option>
                </Tooltip>
              );
            },

            MultiValueLabel: (props) => {
              const value = props.data.value;
              //const pdos = props.data[pdoType];
              const isSelected = value === family["selectedOption"][pdoType];

              return (
                <Button
                  sx={{
                    zIndex: 0
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    //e.cancelable;
                    updateFamily({
                      ...family,
                      selectedOption: {
                        ...family["selectedOption"],
                        [pdoType]: props.data.value
                      }
                    });
                  }}
                  appearance={isSelected ? "primary" : "default"}
                >
                  {props.data.label}
                </Button>
              );
            },

            GroupHeading: (props) => {
              const groupStyles = {
                //color: "rgb(65,66,112)",
                //background: "rgb(65,66,112)",
                padding: "5px 0px",
                display: "flex"
              };
              return (
                <div style={groupStyles}>
                  <components.GroupHeading {...props} />
                </div>
              );
            }
          }}
          isMulti
        />
      </Box>
    </Flex>
  );
};

export default PDOSelect;
