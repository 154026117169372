import React from "react";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { Flex, Box, Text } from "rebass";

import JiraFailedBuildStatusIcon from "@atlaskit/icon/glyph/jira/failed-build-status";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";

toast.notify.success = ({ icon, message }) =>
  toast.notify(
    <Flex alignItems="center">
      <Box color="#86C038">
        {icon ? (
          icon
        ) : (
          <EditorSuccessIcon size="large" primaryColor="inherit" />
        )}
      </Box>
      <Flex pl={2} flexDirection="column" justifyContent="left">
        <Text as="h4" pb={1} textAlign="left" color="#86C038">
          Success
        </Text>
        <Text as="h5" textAlign="left" color="#A3A4A8" fontWeight="500">
          {message}
        </Text>
      </Flex>
    </Flex>
  );

toast.notify.error = ({ icon, message }) =>
  toast.notify(
    <Flex alignItems="center">
      <Box color="#E53C3C">
        {icon ? (
          icon
        ) : (
          <JiraFailedBuildStatusIcon size="large" primaryColor="inherit" />
        )}
      </Box>
      <Flex pl={2} flexDirection="column" justifyContent="left">
        <Text as="h4" pb={1} textAlign="left" color="#E53C3C">
          Error
        </Text>
        <Text as="h5" textAlign="left" color="#A3A4A8" fontWeight="500">
          {message}
        </Text>
      </Flex>
    </Flex>
  );
export default toast;
