import axios from "axios";
import {
  handleGetProjectListResponse,
  handleGetProjectInfoResponse,
  handleProjectCreatedResponse,
  handleProjectDeletedResponse,
  handleErrorResponse
} from "../helpers/handleApiServerResponse";

function getProjectList() {
  return axios(`http://localhost:5001/api/users/projects`, {
    method: "GET",
    withCredentials: "same-origin",
    headers: {
      accept: "*/*",
      "content-type": "application/json"
    }
  })
    .then(
      res => handleGetProjectListResponse(res),
      error => handleErrorResponse(error)
    )
    .then(projects => {
      return Promise.resolve(projects);
    });
}

function getProjectInfo(projectId) {
  return axios(`http://localhost:5001/api/users/projects/${projectId}`, {
    method: "GET",
    withCredentials: "same-origin",
    headers: {
      accept: "*/*",
      "content-type": "application/json"
    }
  })
    .then(
      res => handleGetProjectInfoResponse(res),
      error => handleErrorResponse(error)
    )
    .then(projectInfo => {
      return Promise.resolve(projectInfo);
    });
}

function deleteProjectById(id) {
  return axios(`http://localhost:5001/api/users/projects`, {
    method: "DELETE",
    withCredentials: "same-origin",
    headers: {
      accept: "*/*",
      "content-type": "application/json"
    },
    data: {
      id: id
    }
  })
    .then(
      res => handleProjectDeletedResponse(res),
      error => handleErrorResponse(error)
    )
    .then(deletedProject => {
      return Promise.resolve(deletedProject);
    });
}

function updateProjectInfoById(projectId, projectInfo) {
  return axios(`http://localhost:5001/api/users/projects/${projectId}`, {
    method: "PUT",
    withCredentials: "same-origin",
    headers: {
      accept: "*/*",
      "content-type": "application/json"
    },
    data: projectInfo
  })
    .then(
      res => handleProjectDeletedResponse(res),
      error => handleErrorResponse(error)
    )
    .then(deletedProject => {
      return Promise.resolve(deletedProject);
    });
}
const createNewProject = () => {
  return axios(`http://localhost:5001/api/users/projects/`, {
    method: "POST",
    withCredentials: "same-origin"
  })
    .then(
      res => handleProjectCreatedResponse(res),
      error => handleErrorResponse(error)
    )
    .then(responseData => Promise.resolve(responseData));
};

export const usersProjectService = {
  getProjectInfo,
  getProjectList,
  deleteProjectById,
  createNewProject,
  updateProjectInfoById
};
