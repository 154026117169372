import React from "react";
import { Flex, Box, Text } from "theme-ui";
import { Image } from "rebass";
import erobotLogo from "../assets/images/erobot.png";

/*
const PageHeader = props => {
  const { title } = props;
  return (
    <Flex
      mx="auto"
      pr={[2, 3, 4]}
      sx={{
        alignItems: "center",
        maxWidth: "1024px"
      }}
    >
      
      <Image src={erobotLogo} width={[60, 80, 80]} />
      {title && (
        <Box
          mx={3}
          sx={{
            height: "70%",
            borderRight: "2px solid #45484A"
          }}
        />
      )}
      <Text as="h3">{title}</Text>
      <Box mx="auto" />
      <Flex>{props.children}</Flex>
    </Flex>
  );
};
*/
const PageHeader = (props) => {
  const { title } = props;
  return (
    <Flex
      mx="auto"
      pr={[2, 3, 4]}
      sx={{
        alignItems: "center",
        maxWidth: "1024px"
      }}
    >
      <Text
        as="h3"
        sx={{
          fontWeight: 600,
          fontSize: "24px",
          fontStyle: "italic"
        }}
      >
        {title}
      </Text>
      <Box mx="auto" />
      <Flex>{props.children}</Flex>
    </Flex>
  );
};
export default PageHeader;
