import React, { useState } from "react";
import { Box } from "theme-ui";

import SlaveItem from "./SlaveItem";
import { AnimatedList } from "react-animated-list";
import { projectConfigService } from "../../services/projectConfigService";
const SlaveListDisplay = props => {
  const { pdoMapFamilies } = props;
  const { slaveInfo } = props;

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const removeSlaveAt = _index => {
    projectConfigService.removeSlaveAt(_index);
  };

  const updateSlaveAt = (slaveIndex, slaveInfo) => {
    projectConfigService.updateSlaveAt(slaveIndex, slaveInfo);
  };

  const cloneSlaveAt = (_index, _quantity) => {
    projectConfigService.cloneSlaveAt(_index, _quantity);
  };

  const itemDeletable = slaveInfo ? slaveInfo.length > 1 : false;

  return (
    <AnimatedList
      initialAnimationDuration="300"
      key="slaveAnimatedList"
      animation="fade"
    >
      {slaveInfo.map((slaveInfo, index) => {
        const isActive = index === selectedItemIndex;
        return (
          <div key={`box-${index}`}>
            <Box
              sx={{
                py: 2,
                px: 2
              }}
            >
              <SlaveItem
                key={index}
                pdoMapFamilies={pdoMapFamilies}
                slaveIndex={index}
                isActive={isActive}
                onDeleteItem={removeSlaveAt}
                onCloneItem={cloneSlaveAt}
                onClick={() => {
                  setSelectedItemIndex(index);
                }}
                itemDeletable={itemDeletable}
                slaveInfo={slaveInfo}
                updateSlave={updateSlaveAt}
              />
            </Box>
          </div>
        );
      })}
    </AnimatedList>
  );
};

export default SlaveListDisplay;
