import React, { useState } from "react";
import Tabs from "../components/tabs/Tabs";

import FileUploadTab from "../components/tabs/_FileUploadTab";
import SlaveEditorTab from "../components/tabs/_SlaveEditorTab";
import FamilyEditorTab from "../components/tabs/_FamilyEditorTab";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const tabs = [
  {
    title: "Upload Files",
    tab: (props) => <FileUploadTab key="fileUploadTab" {...props} />
  },

  {
    title: "Family Editor",
    tab: (props) => <FamilyEditorTab key="familyEditorTab" {...props} />
  },
  {
    title: "Slave Editor",
    tab: (props) => <SlaveEditorTab key="slaveEditorTab" {...props} />
  }
];

const EditorPage = (props) => {
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0);
  const changeTab = (tabIndex) => {
    if (tabIndex > -1 && tabIndex < tabs.length) setTabSelectedIndex(tabIndex);
  };

  return (
    <div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

      <Tabs
        tabs={tabs}
        tabSelectedIndex={tabSelectedIndex}
        setTabSelectedIndex={setTabSelectedIndex}
        changeTab={changeTab}
      />
    </div>
  );
};

export default EditorPage;
