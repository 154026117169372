import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Flex, Box } from "theme-ui";
import CrossIcon from "@atlaskit/icon/glyph/cross";

const modalRoot = document.getElementById("modal-root");
export const ModalDialog = (props) => {
  const { components, onClose } = props;

  const [fadeType, setFadeType] = useState();

  const onTransitionEnd = (e) => {
    if (e.propertyName !== "opacity" || fadeType === "fade-in") return;
    if (fadeType === "fade-out") {
      onClose && onClose();
    }
  };

  const onEscKeydown = (e) => {
    if (e.key !== "Escape") return;
    setFadeType("fade-out");
  };

  useEffect(() => {
    window.addEventListener("keydown", onEscKeydown, false);
    setFadeType("fade-in");
    return () => {
      // component will unmount
      window.removeEventListener("keydown", onEscKeydown, false);
    };
  }, []);

  const handleClick = (e) => {
    // Click on background to quit
    e.preventDefault();
    setFadeType("fade-out");
  };

  return ReactDOM.createPortal(
    <Flex
      role="dialog"
      className={fadeType}
      onTransitionEnd={onTransitionEnd}
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        flexDirection: "column",
        opacity: "0",
        transition: "opacity linear 0.2s",
        zIndex: "2000",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "300px",
        margin: "40px auto",

        "&.fade-in": {
          opacity: 1,
          transition: "opacity linear 0.2s"
        },
        "&.fade-out": {
          opacity: 0,
          transition: "opacity linear 0.2s"
        }
      }}
    >
      <Flex
        sx={{
          zIndex: 1050,

          flexDirection: "column",
          backgroundColor: "#fefefe",
          boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)"
        }}
      >
        <Flex
          sx={{
            alignItems: "right",
            justifyContent: "right"
          }}
        >
          <Box
            sx={{
              width: "100%"
            }}
          />
          <Flex
            p={3}
            m={1}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: "30px",
              width: "30px",
              color: "rgba(250, 20, 20, 0.8)",
              ":hover": {
                borderRadius: "3px",
                color: "rgba(250, 20, 20, 0.6)",
                background: "rgba(120, 120, 120, 0.2)"
              }
            }}
            onClick={() => {
              setFadeType("fade-out");
            }}
          >
            <CrossIcon size="small" />
          </Flex>
        </Flex>
        {components && components.Body && (
          <Box
            sx={{
              height: "100%"
            }}
          >
            <components.Body />
          </Box>
        )}
        {components && components.Header && <components.Footer />}
      </Flex>
      <Flex
        onClick={handleClick}
        sx={{
          background: "rgba(0, 0 ,0, 0.5)",
          zIndex: 1040,
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "block",
          outline: 0
        }}
      />
    </Flex>,
    modalRoot
  );
};
