import React from "react";
import { Box, Flex, Button, Text } from "rebass";

import AnimatedFileList from "./AnimatedFileList";
import AddCircleIcon from "@atlaskit/icon/glyph/add-circle";
import { useDropzone } from "react-dropzone";

const FileImportedScreen = (props) => {
  const {
    acceptedFileList,
    handleDeleteFileByName,
    handleUpload,
    onDrop
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xml, .esi"
  });

  const numberOfFiles = Object.keys(acceptedFileList).length;

  return (
    <Flex
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      my={[3, 1]}
      mx={[2, 1]}
      css={{
        borderRadius: 4,
        borderColor: "#D0D0D1"
      }}
    >
      <Flex
        bg="#F9F9FA"
        width="100%"
        flexDirection="column"
        flex={1}
        justifyContent="space-between"
      >
        <Flex
          width="100%"
          maxHeight={220}
          overflow={"auto"}
          flexDirection="column"
        >
          <AnimatedFileList
            fileList={acceptedFileList}
            handleDeleteFileByName={handleDeleteFileByName}
          />
        </Flex>
        <Box px={3} py={2}>
          <Flex p={2} justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" {...getRootProps()}>
              <AddCircleIcon />
              <Text as="h4" ml={2}>
                Select more files
              </Text>
              <input {...getInputProps()} />
            </Flex>
            <Text as="h5">{numberOfFiles} files</Text>
          </Flex>
        </Box>
      </Flex>

      <Button
        mt={3}
        bg="#172B4D"
        width="100%"
        onClick={handleUpload}
        css={{
          ":hover": {
            backgroundColor: "#003eaa"
          },
          fontSize: "16px"
        }}
      >
        Upload
      </Button>
    </Flex>
  );
};

export default FileImportedScreen;
