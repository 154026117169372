import { toast } from "react-toastify";
export const notifyFileDuplicated = fileName => {
  toast.info(` 🚀 File existed: \n ${fileName}`, {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  });
};

export const notifyFileSaved = fileName => {
  let info = ` 🚀 All files removed successfully!!`;
  if (fileName) {
    info = ` 🚀 Files saved: \n ${fileName}`;
  }
  toast.info(info, {
    position: "top-left",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  });
};

export const notifyParsingFileError = (fileName, error) => {
  toast.error(` 🚀 Parse file ${fileName} error: ${error}`, {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  });
};

export const notifyResponseError = error => {
  toast.error(` 🚀 Fail: '${error}'`, {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  });
};

export const notifyEmptyPdoMapsFile = () => {
  toast.error(
    ` 🚀 There is no pdo maps file in your project. Let's upload them!`,
    {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    }
  );
};
