//https://github.com/cornflourblue/react-jwt-authentication-example/blob/master/src/_services/authentication.service.js
import { BehaviorSubject, throwError } from "rxjs"; // as a Subject emitter

import {
  handleAuthResponse,
  handleErrorResponse
} from "../helpers/handleApiServerResponse";

import axios from "axios";

// create currentUser emiiter, initial value from local storage
let storagedUserInfo = undefined;

try {
  storagedUserInfo = JSON.parse(localStorage.getItem("currentUser"));
} catch (e) {}

const currentUserSubject = new BehaviorSubject(storagedUserInfo);

export const authenticationService = {
  signin,
  logout,
  signup,

  currentUser: currentUserSubject.asObservable(),
  get userAuhthenticated() {
    return currentUserSubject.value != null;
  },
  get currentUserValue() {
    return currentUserSubject.value;
  }
};

function signin(userInfo) {
  return axios(`http://localhost:5001/api/users/authenticate`, {
    method: "POST",
    data: userInfo,
    withCredentials: "same-origin",

    headers: {
      accept: "*/*",
      "content-type": "application/json"
    }
  })
    .then(
      (res) => handleAuthResponse(res),
      (error) => handleErrorResponse(error)
    )
    .then((user) => {
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);
      return Promise.resolve(user);
    });
}

function logout() {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
  return Promise.resolve();
}

function signup(userInfo) {
  return axios
    .post(`http://localhost:5001/api/users`, userInfo)
    .then((res) => {
      const user = res.data.user;
      if (user) {
        localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);

        return user;
      }
      return null;
    })
    .catch((error) => {
      localStorage.removeItem("currentUser");
      currentUserSubject.next(null);
      throwError(error);
    });
}
