import React, { useState } from "react";
import { Flex, Box, Text, Button } from "theme-ui";
import { Input } from "theme-ui";

//import { Input } from "@rebass/forms";

import styled from "styled-components";

const Label = styled(Text)`
  color: #929292;
  font-weight: 600;
  letter-spacing: 1.5px;
`;

const Inputfield = styled(Input)`
  padding: "14px";
  border: none;
  background: #f0f0f0;
  border-radius: 5px;
`;

const ProjectSettingForm = props => {
  const { defaultSetting, updateProjectSettings } = props;
  const [settings, setSettings] = useState(
    defaultSetting || {
      codeGenServerIP: "localhost",
      codeGenServerPORT: 5001
    }
  );

  const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const updateSetting = e => {
    const name = e.target.name;
    let value = e.target.value;

    if (e.target.type === "number" && value !== "") {
      value = parseInt(value, 10);

      setSettings(prevSettings => ({
        ...prevSettings,
        [name]: value
      }));
    } else if (e.target.name === "codeGenServerIP") {
      if (value.match(ipformat) || value === "localhost") {
        setSettings(preveSettings => ({
          ...preveSettings,
          [name]: value
        }));
      }
    }
  };

  const onSaveSetting = e => {
    e.preventDefault();
    updateProjectSettings && updateProjectSettings(settings);
  };
  return (
    <Box
      sx={{
        m: [1, 2, 2]
      }}
    >
      <Flex
        sx={{
          mx: "auto",
          width: ["auto", 512, 512]
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            flex: 1
          }}
        >
          <Text
            as="h4"
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: 600,
              letterSpacing: "1px",
              py: 3,
              borderBottom: "2px dashed white"
            }}
          >
            CODE GENERATOR SERVER
          </Text>
          <Flex
            sx={{
              py: 3,
              px: 2
            }}
          >
            <Box
              sx={{
                flex: 2 / 3,
                pr: 2
              }}
            >
              <Label htmlFor="IP" fontSize={["9.5px", "14px", "14px"]}>
                Server IP
              </Label>
              <Inputfield
                mt={1}
                py={[1, 2, 2]}
                defaultValue={settings.codeGenServerIP}
                name="codeGenServerIP"
                type="text"
                variants="ip"
                onChange={e => updateSetting(e)}
              />
            </Box>

            <Box
              sx={{
                pl: 2,
                flex: 1 / 3
              }}
            >
              <Label htmlFor="IP" fontSize={["9.5px", "14px", "14px"]}>
                Port
              </Label>
              <Inputfield
                mt={1}
                py={[1, 2, 2]}
                defaultValue={settings.codeGenServerPORT}
                name="codeGenServerPORT"
                type="number"
                min={0}
                onChange={e => updateSetting(e)}
              />
            </Box>
          </Flex>

          <Flex
            sx={{
              flex: 1,
              px: 2,
              pb: 3
            }}
          >
            <Button
              onClick={onSaveSetting}
              sx={{
                width: "100%"
              }}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProjectSettingForm;
