import React from "react";
import Clickable from "./Clickable";
import { Flex, Text, Box } from "rebass";

const ValueDisplay = props => {
  const { label, value } = props;
  return (
    <Clickable {...props}>
      <Flex
        sx={{
          flexDirection: "column",
          py: [1],
          mx: [3, 4, 4],
          justifyContent: "center"
        }}
      >
        <Text
          as="h4"
          sx={{
            textAlign: "center",
            color: "inherit",
            fontSize: ["12px", "14px", "16px"]
          }}
        >
          {label}
        </Text>
        <Box
          sx={{
            my: 1,
            width: "100%",
            border: "0.8px solid #0D594C"
          }}
        />
        <Text
          as="h5"
          sx={{
            textAlign: "center",
            color: "inherit"
          }}
        >
          {value}
        </Text>
      </Flex>
    </Clickable>
  );
};

export default ValueDisplay;
