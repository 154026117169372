import React, { useState, useEffect } from "react";
import { Flex, Box, Input } from "theme-ui";
import TabNavigation from "./TabNavigation";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Dropdown, { DropdownItem } from "@atlaskit/dropdown-menu";

import SignOutIcon from "@atlaskit/icon/glyph/sign-out";
import DashboardIcon from "@atlaskit/icon/glyph/dashboard";
import SettingsIcon from "@atlaskit/icon/glyph/settings";

import { Avatar, Text } from "theme-ui";
import avatar from "../../assets/images/waiting-robot.gif";
import { useHistory } from "react-router-dom";
import { authenticationService } from "../../services/authenticationService";
import { ModalDialog } from "../../components/molecules/Modal";
import ProjectSettingForm from "../../components/ProjectSettingForm";
import { pdoMapFamiliesService } from "../../services/pdoMapFamiliesService";
import { projectConfigService } from "../../services/projectConfigService";
import { storagedFilesService } from "../../services/storagedFilesService";
import { usersProjectService } from "../../services/usersProjectService";
import { useParams } from "react-router-dom";
import { notifyResponseError } from "../../components/toast-notifiers";
import _ from "lodash";

function Tabs(props) {
  const { tabs, tabSelectedIndex, changeTab, setTabSelectedIndex } = props;
  const history = useHistory();
  const [showSettingDialog, setShowSettingDialog] = useState(false);

  const [projectInfo, setProjectInfo] = useState();

  const { projectId } = useParams();

  useEffect(() => {
    if (authenticationService.userAuhthenticated) {
      projectId &&
        usersProjectService
          .getProjectInfo(projectId)
          .then((projectInfo) => {
            setProjectInfo(projectInfo);
          })
          .catch((err) => {
            notifyResponseError(err.errors[0]);
          });
      return () => {
        pdoMapFamiliesService.clear();
        projectConfigService.clear();
        storagedFilesService.clear();
      };
    }
  }, []);

  const updateProjectName = (projectName) => {
    projectInfo && (projectInfo["name"] = projectName);
    if (authenticationService.userAuhthenticated) {
      projectName &&
        usersProjectService
          .updateProjectInfoById(projectId, projectInfo)
          .then((_projectInfo) => {
            setProjectInfo(_projectInfo);
          })
          .catch((error) => {});
    } else setProjectInfo({ ...projectInfo });
  };

  const updateProjectSettings = (settings) => {
    if (authenticationService.userAuhthenticated) {
      projectInfo && settings && (projectInfo["settings"] = settings);
      settings &&
        usersProjectService
          .updateProjectInfoById(projectId, projectInfo)
          .then((_projectInfo) => {
            setProjectInfo({ ..._projectInfo });
            setShowSettingDialog(false);
          })
          .catch((error) => {
            setShowSettingDialog(false);
          });
    } else setProjectInfo({ ...projectInfo });
  };
  const signOut = () => {
    authenticationService.logout().then(() => {
      history.push("/");
    });
  };
  const gotoDashboard = () => {
    history.push("/dashboard");
  };

  const openSettingDialog = () => {
    setShowSettingDialog(true);
  };
  const delayUpdateProjectName = _.debounce((e) => {
    return updateProjectName(e);
  }, 1000);

  return (
    <Flex
      sx={{
        m: "auto",
        flexDirection: "column",
        maxWidth: "1268px"
      }}
    >
      {showSettingDialog && (
        <ModalDialog
          components={{
            Body: () => (
              <ProjectSettingForm
                defaultSetting={projectInfo && projectInfo.settings}
                updateProjectSettings={updateProjectSettings}
              />
            )
          }}
          onClose={() => {
            setShowSettingDialog(false);
          }}
        />
      )}

      <Flex
        sx={{
          flexDirection: "row",
          justifyItem: "center",
          alignContent: "center"
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            bg: "rgba(251,186,70, 0.9)",
            pt: [1],
            px: [2, 3, 3]
          }}
        >
          <Flex
            sx={{
              flex: 1
            }}
          />
          {false && (
            <Dropdown
              trigger={
                <Avatar
                  src={avatar}
                  sx={{
                    width: [32, 42, 48],
                    height: [32, 42, 48],
                    bg: "#f1f2f2",
                    ":hover": {
                      cursor: "pointer"
                    }
                  }}
                />
              }
              position="bottom left"
            >
              <DropdownItem
                onClick={gotoDashboard}
                elemBefore={<DashboardIcon size="small" />}
              >
                My dashboard
              </DropdownItem>
              <DropdownItem
                onClick={openSettingDialog}
                elemBefore={<SettingsIcon size="small" />}
              >
                Project settings
              </DropdownItem>
              <DropdownItem
                onClick={signOut}
                elemBefore={<SignOutIcon size="small" />}
              >
                Sign out
              </DropdownItem>
            </Dropdown>
          )}
          <Flex
            sx={{
              flexDirection: "column"
            }}
          >
            <Text
              as="h3"
              sx={{
                pl: [1, 2, 2],
                fontSize: ["14px", "16px", "18px"],
                fontStyle: "italic"
              }}
            >
              Simple Robot
            </Text>
            <Input
              defaultValue={projectInfo && projectInfo.name}
              onChange={(e) => {
                e.preventDefault();
                delayUpdateProjectName(e.target.value);
              }}
              sx={{
                color: "#FAFAFA",
                textAlign: "left",
                py: 0,
                pl: [1, 2, 2],
                pr: 0,
                width: "120px",
                outline: "none",
                border: "none",
                cursor: "pointer",
                fontSize: ["12px", "14px", "16px"],
                fontStyle: "italic",
                ":focus": {
                  outline: "none"
                }
              }}
            />
          </Flex>
        </Flex>

        <TabNavigation
          {...props}
          sx={{
            bg: "red",
            flex: 1
          }}
        />
      </Flex>
      <Box
        sx={{
          minWidth: "380px"
        }}
        my={"auto"}
      >
        <SwipeableViews
          index={tabSelectedIndex}
          enableMouseEvents
          onChangeIndex={(index) => {
            //changeTab(index);
            setTabSelectedIndex && setTabSelectedIndex(index);
          }}
        >
          {tabs &&
            tabs.map((tab) => {
              return tab.tab({ changeTab: changeTab });
            })}
        </SwipeableViews>
      </Box>
    </Flex>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  tabSelectedIndex: PropTypes.number,
  setTabSelectedIndex: PropTypes.func
};

export default Tabs;
