// PDOFamilyEditorScreen
import React from "react";

import { Flex, Box, Text } from "rebass";
import PDOMaterialEntryTable from "./PDOMaterialEntryTable";
import PDOSelect from "./PDOSelect";
const PDOFamilyEditorScreen = props => {
  const {
    pdoFamily,
    updateFamily,

    rxPDOOptions,
    txPDOOptions
  } = props;

  const selectedPDOFamilyId = pdoFamily.id;

  let rxPDOSelectedIndex = null;
  let txPDOSelectedIndex = null;
  let rxPDOSelected = null;
  let txPDOSelected = null;

  if (pdoFamily["selectedOption"] && pdoFamily["selectedOption"]["RxPDO"]) {
    const selectedValue = pdoFamily["selectedOption"]["RxPDO"];

    rxPDOSelectedIndex = pdoFamily["RxPDO"]
      .map(pdoSelect => pdoSelect.value)
      .indexOf(selectedValue);

    rxPDOSelectedIndex > -1 &&
      (rxPDOSelected = pdoFamily["RxPDO"][rxPDOSelectedIndex]);
  }
  if (pdoFamily["selectedOption"] && pdoFamily["selectedOption"]["TxPDO"]) {
    const selectedValue = pdoFamily["selectedOption"]["TxPDO"];
    txPDOSelectedIndex = pdoFamily["TxPDO"]
      .map(pdoSelect => pdoSelect.value)
      .indexOf(selectedValue);

    txPDOSelectedIndex > -1 &&
      (txPDOSelected = pdoFamily["TxPDO"][txPDOSelectedIndex]);
  }

  return (
    <Flex
      sx={{
        flexDirection: ["column", "column", "row"],
        mx: [0, 2, 2]
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          flex: [1, 1, 49 / 100],
          bg: "#E6E6E6",
          justifyContent: "top",
          py: 3
        }}
      >
        <Box
          sx={{
            mb: 2,
            ml: 3
          }}
        >
          <Text as="h4">RX PDO Maps</Text>
        </Box>

        <PDOSelect
          family={pdoFamily}
          pdoType={"RxPDO"}
          updateFamily={updateFamily}
          pdoSelectOption={rxPDOOptions}
          selectedPDOFamilyId={selectedPDOFamilyId}
        />

        {rxPDOSelected && (
          <Box
            sx={{
              p: 2
            }}
          >
            <PDOMaterialEntryTable
              pdoFamily={pdoFamily}
              pdoType={"RxPDO"}
              pdoSelectedIndex={rxPDOSelectedIndex}
              pdoIndex={rxPDOSelected["pdo"].index}
              pdoName={rxPDOSelected["pdo"].name}
              _entries={rxPDOSelected["pdo"].entries}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: ["100%", 1 / 50],
          height: ["0px", "20px", "auto"],
          px: [0, "6px"],
          py: ["20px", "0px"]
        }}
      />

      <Box
        sx={{
          py: 3,
          flexDirection: "column",
          flex: [1, 1, 49 / 100],
          bg: "#E6E6E6"
        }}
      >
        <Box mb={2} ml={3}>
          <Text as="h4">TX PDO Maps</Text>
        </Box>

        <PDOSelect
          family={pdoFamily}
          pdoType={"TxPDO"}
          updateFamily={updateFamily}
          pdoSelectOption={txPDOOptions}
          selectedPDOFamilyId={selectedPDOFamilyId}
        />

        {txPDOSelected && (
          <Box
            sx={{
              p: 2
            }}
          >
            <PDOMaterialEntryTable
              pdoFamily={pdoFamily}
              pdoType={"TxPDO"}
              pdoSelectedIndex={txPDOSelectedIndex}
              pdoIndex={txPDOSelected["pdo"].index}
              pdoName={txPDOSelected["pdo"].name}
              _entries={txPDOSelected["pdo"].entries}
            />
          </Box>
        )}
      </Box>
    </Flex>
  );
};
export default PDOFamilyEditorScreen;
