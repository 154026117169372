import React from "react";
import { Text } from "theme-ui";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
const FamilyDeleteNotification = (props) => {
  const { isDeleteAllModalOpen, setIsDeleteAllModalOpen, onEmptyList } = props;
  return (
    <ModalTransition>
      {isDeleteAllModalOpen && (
        <Modal
          width={360}
          appearance="warning"
          key="empty-family"
          heading="Delete all families?"
          actions={[
            {
              text: "No, I don't",
              onClick: () => {
                setIsDeleteAllModalOpen(false);
              }
            },
            {
              text: "Yes, I know.",
              onClick: () => {
                onEmptyList();
              }
            }
          ]}
        >
          <Text as="h5">
            All available famillies will be removed. You don't have a chance for
            recovery them.
          </Text>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default FamilyDeleteNotification;
