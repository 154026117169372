import React from "react";
import Flag from "@atlaskit/flag";
import { Text } from "theme-ui";
import Warning from "@atlaskit/icon/glyph/warning";
const WarningFlag = props => {
  const { changeTab } = props;
  return (
    <Flag
      actions={[
        {
          content: "Ok. Let's edit family",
          onClick: () => {
            changeTab && changeTab(1);
          }
        }
      ]}
      appearance="warning"
      title={<Text as="h4">You missing something..</Text>}
      description={
        <Text as="h6">
          PDO Map family not configured. You should go to Family Editor tab
        </Text>
      }
      icon={<Warning size="large" />}
      key="warning-flag"
      id="wanrning-flag"
      isDismissAllowed
    />
  );
};

export default WarningFlag;
