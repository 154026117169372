const exportResult = (pdoMapFamilies, slaveInfos, projectInfo) => {
  let result = { ProjectInfo: [], SlaveInfo: [], PDOMap: [] };
  //projectInfo && (projectInfo[1]["SlaveNum"] = slaveInfos.length);
  projectInfo && (result["ProjectInfo"] = [...projectInfo]);
  projectInfo &&
    (projectInfo[1]["SlaveNum"] = slaveInfos.reduce(
      (acc, slave) => acc + slave.copies,
      0
    ));

  slaveInfos &&
    slaveInfos.forEach((slaveInfo, index) => {
      result["SlaveInfo"].push({
        Family: parseInt(slaveInfo.familyIndex, 0),
        alias: slaveInfo.alias,

        pos: slaveInfo.pos,
        copies: slaveInfo.copies,
        vid: pdoMapFamilies[slaveInfo.familyIndex]["vid"],
        proCode: pdoMapFamilies[slaveInfo.familyIndex]["proCode"].replace(
          "#x",
          "0x"
        ),
        name: pdoMapFamilies[slaveInfo.familyIndex]["deviceName"]
      });
    });

  pdoMapFamilies &&
    Object.keys(pdoMapFamilies).forEach((familyIndex) => {
      result["PDOMap"].push({
        Family: parseInt(familyIndex, 10),
        Profile: pdoMapFamilies[familyIndex]["profileNo"],
        RxPDO: pdoMapFamilies[familyIndex]["RxPDO"].map((rxPDO) => {
          let newRxPDO = { ...rxPDO["pdo"] };
          delete newRxPDO["exclude"];
          return newRxPDO;
        }),
        TxPDO: pdoMapFamilies[familyIndex]["TxPDO"].map((txPDO) => {
          let newTxPDO = { ...txPDO["pdo"] };
          delete newTxPDO["exclude"];
          return newTxPDO;
        })
      });
    });
  return result;
};

export default exportResult;
