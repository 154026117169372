//PDOMaterialEntryTable
import React, { useState } from "react";
import MaterialTable, { MTableCell } from "material-table";
import Paper from "@material-ui/core/Paper";

import PDOMaterialTableTitle from "./PDOMaterialTableTitle";
import { pdoMapFamiliesService } from "../services/pdoMapFamiliesService";
const PDOMaterialEntryTable = props => {
  const [selectedRow, setSelectedRow] = useState();
  const columns = [
    {
      title: "index",
      field: "index",
      headerStyle: {
        width: "100px",
        textAlign: "center",
        alignItem: "center",
        paddingLeft: "10px",
        paddingRight: "00px"
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      title: "subId",
      field: "subIndex",
      headerStyle: {
        width: "100px",
        textAlign: "center",
        alignItem: "center",
        paddingLeft: "0px",
        paddingRight: "0px"
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      title: "dType",
      field: "dType",
      headerStyle: {
        width: "100px",
        textAlign: "center",
        alignItem: "center",
        paddingLeft: "0px",
        paddingRight: "0px"
      }
    },
    { title: "name", field: "name" },
    { title: "bits", field: "bitLength" }
  ];

  const { _entries } = props;
  const {
    caption,
    pdoName,
    pdoFamily,
    pdoType,
    pdoSelectedIndex,
    pdoIndex
  } = props;

  const insertEntry = newEntry => {
    return new Promise((resolve, reject) => {
      const newEntries = [..._entries, newEntry];

      pdoMapFamiliesService.updateFamilyPDOEntry(
        pdoFamily,
        pdoType,
        pdoSelectedIndex,
        newEntries
      );
      resolve();
    });
  };

  const updateEntry = (newEntry, oldEntry) => {
    return new Promise((resolve, reject) => {
      const index = _entries.indexOf(oldEntry);
      _entries[index] = newEntry;

      pdoMapFamiliesService.updateFamilyPDOEntry(
        pdoFamily,
        pdoType,
        pdoSelectedIndex,
        _entries
      );

      resolve();
    });
  };

  const deleteEntry = oldEntry => {
    return new Promise((resolve, reject) => {
      const index = _entries.indexOf(oldEntry);
      _entries.splice(index, 1);
      pdoMapFamiliesService.updateFamilyPDOEntry(
        pdoFamily,
        pdoType,
        pdoSelectedIndex,
        _entries
      );
      setSelectedRow(null);
      resolve();
    });
  };

  const customComponents = {
    Container: props => {
      return <Paper p={2} {...props} elevation={3} />;
    },
    Cell: props => {
      return (
        <MTableCell
          {...props}
          style={{
            paddingLeft: "10px",
            width: "10px"
          }}
        />
      );
    }
  };

  const onRowClick = (event, _selectedRow) => {
    if (selectedRow !== _selectedRow) setSelectedRow(_selectedRow);
    else {
      setSelectedRow(null);
    }
  };
  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        style={{
          "th, td": {
            padding: "15px",
            textAlign: "center",
            borderBottom: "none"
          }
        }}
        title={
          <PDOMaterialTableTitle
            pdoName={pdoName}
            caption={caption}
            pdoIndex={pdoIndex}
          />
        }
        columns={columns}
        data={_entries}
        options={{
          search: false,
          padding: "dense",
          paging: false,
          actionsColumnIndex: 0,
          rowStyle: rowData => ({
            textAlign: "center",
            margin: "32px",
            backgroundColor:
              selectedRow && selectedRow.tableData.id === rowData.tableData.id
                ? "#EEE"
                : "#FFF"
          }),
          headerStyle: {
            fontWeight: "bold",
            zIndex: 0
          }
        }}
        editable={{
          onRowAdd: insertEntry,
          onRowDelete: selectedRow && deleteEntry,
          onRowUpdate: selectedRow && updateEntry
        }}
        components={customComponents}
        onRowClick={onRowClick}
      />
    </div>
  );
};

export default PDOMaterialEntryTable;
