import React, { useState } from "react";

import { Flex, Box, Text, Button } from "theme-ui";
import PageHeader from "../components/PageHeader";

import { ModalDialog } from "../components/molecules/Modal";
import SigninSignupForm from "../components/forms/SigninSignupForm";
import shortid from "shortid";

/*
const HomePage = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typeOfForm, setTypeOfForm] = useState("");
  const { history } = props;
  return (
    <Box pt={[2, 3, 3]}>
      {isModalOpen && (
        <ModalDialog
          isOpen={isModalOpen}
          components={{
            Body: () => (
              <SigninSignupForm typeOfForm={typeOfForm} history={history} />
            )
          }}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}

      <PageHeader>
        <Flex
          sx={{
            alignItems: "center"
          }}
        >
          <Text
            mx={3}
            onClick={() => {
              setTypeOfForm("signin");
              setIsModalOpen(true);
            }}
            sx={{
              ":hover": {
                cursor: "pointer"
              }
            }}
          >
            Sign in
          </Text>
          <Button
            variant="header"
            onClick={() => {
              setTypeOfForm("signup");
              setIsModalOpen(true);
            }}
          >
            Register
          </Button>
        </Flex>
      </PageHeader>

      <FirstRow {...props} />
    </Box>
  );
};
*/
const HomePage = (props) => {
  return (
    <Box pt={[2, 3, 3]}>
      <PageHeader title={"Simple Robot"} />
      <FirstRow {...props} />
    </Box>
  );
};
const FirstRow = (props) => {
  return (
    <Flex
      pt={3}
      pb={5}
      mx="auto"
      //bg="#A2A5AB"
      color="#0065D5"
      sx={{
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center"
      }}
    >
      <Text pt={5} as="h2" fontSize={[24, 26, 28]}>
        Ethercat slave editor made faster
      </Text>
      <Text as="h4" fontSize={[14, 16, 16]} py={2}>
        Click and see ui for rapid development
      </Text>
      <Button
        mt={3}
        mb={1}
        bg="#FF3333"
        px={[4, 4, 5]}
        py={10}
        sx={{
          border: "none",
          outline: "none",
          ":hover": {
            cursor: "pointer",
            border: "none",
            boxShadow: "3px 4px 5px 0px rgba(222,44,44, 0.6)"
          },
          ":active": {
            cursor: "pointer",
            border: "none",
            boxShadow: "3px 4px 5px 0px rgba(222,44,44, 1)"
          }
        }}
        onClick={() => {
          props.history.push(`/editor/${shortid.generate()}`);
        }}
      >
        Start upload files
      </Button>
      <Text color="#374E60" mt={2} fontSize={[13, 14, 14]}>
        No signup required
      </Text>
    </Flex>
  );
};

const Copyright = (props) => {
  return (
    <Box
      width={"100%"}
      px={4}
      py={3}
      //bg="#2F333E"
      bg="#0A0E12"
      color="#A3A6AC"
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0
      }}
      textAlign="center"
    >
      <Text fontSize="13px" lineHeight="14px" fontWeight="bold">
        Copyright © 2020 Epsilon Robotics
      </Text>
      <Text
        fontSize="13px"
        py={1}
        sx={{
          lineBreak: "auto"
        }}
      >
        EtherCAT® is registered trademark and patented technology by Beckhoff
        Automation GmbH
      </Text>
    </Box>
  );
};
export default HomePage;
