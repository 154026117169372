import React from "react";
import { Flex, Text } from "theme-ui";
import UseAnimations from "react-useanimations";

import shortid from "shortid";
const LoadingComponent = props => {
  const { message, animationKey, sx } = props;

  return (
    <Flex
      sx={{
        width: "100%",

        height: "inherit",
        // bg: "rgba(177,175,124, 0.2)",
        bg: "rgba(5, 28, 20, 0.6)",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        top: 0,
        bottom: 0,
        position: "absolute",
        ...sx
      }}
    >
      <Flex
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <UseAnimations
          animationKey={animationKey || "activity"}
          key={shortid.generate()}
          size={42}
          style={{
            color: "rgb(225, 225, 195, 1)"
          }}
        />
        {message && (
          <Text
            sx={{
              pt: 3,
              textAlign: "center",
              fontSize: 26,
              fontWeight: 300,
              color: "rgb(235, 235, 235, 1)"
            }}
          >
            {message}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default LoadingComponent;
